import React from 'react'
import {
    IoIosArrowBack,
    IoIosArrowForward,
    IoIosArrowUp,
    IoIosArrowDown,
} from 'react-icons/io'

const ArrowButton = ({
    onClick,
    direction,
    disabled,
    iconSize,
    backgroundColor,
    textColor,
}: {
    onClick: () => void
    direction: 'left' | 'right' | 'up' | 'down'
    disabled?: boolean
    iconSize: number
    backgroundColor?: string
    textColor?: string
}) => {
    const renderIcon = () => {
        const sizeClass = `w-${iconSize} h-${iconSize}`
        switch (direction) {
            case 'left':
                return <IoIosArrowBack className={sizeClass} />
            case 'right':
                return <IoIosArrowForward className={sizeClass} />
            case 'up':
                return <IoIosArrowUp className={sizeClass} />
            case 'down':
                return <IoIosArrowDown className={sizeClass} />
            default:
                return null
        }
    }

    return (
        <button
            disabled={disabled}
            className={`flex flex-col items-center rounded-full p-1 ${
                backgroundColor ? 'bg-' + backgroundColor : 'bg-blue'
            } ${textColor ? 'text-' + textColor : 'text-white'} ${
                disabled ? 'cursor-not-allowed' : 'cursor-pointer'
            }`}
            onClick={onClick}
        >
            {renderIcon()}
        </button>
    )
}

export default ArrowButton
