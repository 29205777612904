import apiClient from '../api/apiClient'
import type {
    FetchFulltextResponse,
    IdeaItem,
    IdeaSetItem,
    IntroTextResponse,
    ListIdeaItems,
    QuestionsResponseV2,
} from '../api/openapi'

export const generateIdeas = (
    projectId: string,
    searchId: string
): Promise<QuestionsResponseV2> => {
    return apiClient.getResearchQuestionsV2IdeasGeneratorV2Post({
        questionsRequest: {
            projectId,
            searchId,
            timestamp: new Date().toISOString(),
        },
    })
}

export const getIndividualIdea = (
    projectId: string,
    ideaId: string
): Promise<IdeaItem> => {
    return apiClient.fetchIndividualIdeaFetchIndividualIdeaPost({
        fetchIdeaSet: {
            projectId,
            ideaId,
        },
    })
}

export const fetchIndividualIdeaSet = (
    projectId: string,
    ideasSetId: string
): Promise<IdeaSetItem> => {
    return apiClient.fetchIndividualIdeasSetFetchIndividualIdeasSetPost({
        fetchIdeas: {
            projectId,
            ideasSetId,
        },
    })
}

export const fetchIdeas = (
    projectId: string,
    ideasSetId: string
): Promise<ListIdeaItems> => {
    return apiClient.fetchIdeasV2FetchIdeasV2Post({
        fetchIdeas: {
            projectId,
            ideasSetId,
        },
    })
}
