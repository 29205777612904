import { useEffect, useState } from 'react'
import Card from '../../components/atoms/Card'
import CustomInputField from '../../components/atoms/CustomInputField'
import { CustomButton } from '../../components/buttons/CustomButton'
import { useFetchOnDemand } from '../../hooks/useFetchOnDemand'
import { sendFeedback } from '../../middleware/feedbackService'
import { Title } from '../../components/atoms/Title'
import { Subtitle } from '../../components/atoms/Subtitle'
import { toast } from 'react-toastify'

export const FeedbackForm = ({
    feedbackSent,
}: {
    feedbackSent: () => void
}) => {
    const [feedback, setFeedback] = useState('')
    const {
        fetchData: sendFeedbackCall,
        data,
        loading,
        error,
    } = useFetchOnDemand(() => {
        return sendFeedback(feedback)
    })

    useEffect(() => {
        if (data) {
            if (data.result) {
                setFeedback('')
                toast('Feedback sent!', { type: 'success' })
                //close modal after feedback is sent
                setTimeout(() => {
                    //close modal
                    feedbackSent()
                }, 2000)
            } else {
                toast('Feedback not sent!', { type: 'error' })
            }
        }
    }, [data])

    useEffect(() => {
        if (error) {
            toast('Feedback not sent!', { type: 'error' })
        }
    }, [error])

    return (
        <Card>
            <form className={'flex flex-col gap-4 items-center w-full'}>
                <Title text={'Feedback Form'} />
                <Subtitle
                    text={
                        'Thanks for supporting us! Let us know what you think about the platform!'
                    }
                />
                <CustomInputField
                    className={'w-full'}
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder="Please, be as concise and detailed as you can, this information will be very valuable and taken into consideration for our future iterations and developments on the platform, thanks for your support!"
                    multiline
                />
                <div className={`flex flex-row gap-3 self-end`}>
                    <CustomButton
                        backgroundColor={'lightGray'}
                        textColor={'blue'}
                        text={'Cancel'}
                        onClick={feedbackSent}
                    />
                    <CustomButton
                        text={'Submit'}
                        onClick={sendFeedbackCall}
                        loading={loading}
                    />
                </div>
            </form>
        </Card>
    )
}
