/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FetchPicoInfo
 */
export interface FetchPicoInfo {
    /**
     * List of article IDs
     * @type {Array<any>}
     * @memberof FetchPicoInfo
     */
    articleIds: Array<any>;
}

/**
 * Check if a given object implements the FetchPicoInfo interface.
 */
export function instanceOfFetchPicoInfo(value: object): value is FetchPicoInfo {
    if (!('articleIds' in value) || value['articleIds'] === undefined) return false;
    return true;
}

export function FetchPicoInfoFromJSON(json: any): FetchPicoInfo {
    return FetchPicoInfoFromJSONTyped(json, false);
}

export function FetchPicoInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FetchPicoInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'articleIds': json['article_ids'],
    };
}

export function FetchPicoInfoToJSON(value?: FetchPicoInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'article_ids': value['articleIds'],
    };
}

