import React, { useState } from 'react'
import { IoMdCheckmark } from 'react-icons/io'

const CustomCheckbox = ({
    label,
    checked,
    onChange,
}: {
    label?: string
    checked?: boolean
    onChange?: (isChecked: boolean) => void
}) => {
    const [isChecked, setIsChecked] = useState(checked || false)

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked)
        if (onChange) {
            onChange(!isChecked)
        }
    }

    return (
        <div
            className="inline-flex items-center cursor-pointer"
            onClick={handleCheckboxChange}
        >
            <div
                className={`w-[20px] h-[20px] flex items-center justify-center border-2 rounded ${
                    isChecked ? 'border-blue' : 'border-gray'
                } transition-colors duration-150`}
            >
                {isChecked && (
                    <IoMdCheckmark
                        className={'w-[15px] h-[15px] font-bold text-blue'}
                    />
                )}
            </div>
            {label && (
                <span className="ml-2 text-veryDarkGray dark:text-veryLightGray">
                    {label}
                </span>
            )}
        </div>
    )
}

export default CustomCheckbox
