/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchArticle
 */
export interface SearchArticle {
    /**
     * List of DOIs
     * @type {Array<any>}
     * @memberof SearchArticle
     */
    dois: Array<any>;
    /**
     * Project ID
     * @type {string}
     * @memberof SearchArticle
     */
    projectId: string;
    /**
     * ID of the search where the article will be attached
     * @type {string}
     * @memberof SearchArticle
     */
    searchId: string;
    /**
     * Timestamp when the endpoint call was made
     * @type {string}
     * @memberof SearchArticle
     */
    timestamp: string;
}

/**
 * Check if a given object implements the SearchArticle interface.
 */
export function instanceOfSearchArticle(value: object): value is SearchArticle {
    if (!('dois' in value) || value['dois'] === undefined) return false;
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('searchId' in value) || value['searchId'] === undefined) return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    return true;
}

export function SearchArticleFromJSON(json: any): SearchArticle {
    return SearchArticleFromJSONTyped(json, false);
}

export function SearchArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchArticle {
    if (json == null) {
        return json;
    }
    return {
        
        'dois': json['dois'],
        'projectId': json['project_id'],
        'searchId': json['search_id'],
        'timestamp': json['timestamp'],
    };
}

export function SearchArticleToJSON(value?: SearchArticle | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dois': value['dois'],
        'project_id': value['projectId'],
        'search_id': value['searchId'],
        'timestamp': value['timestamp'],
    };
}

