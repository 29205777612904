import { Configuration, DefaultApi } from './openapi'
import { refreshToken } from '../middleware/authService'
import LocalStorageUtil from '../utils/LocalStorageUtil'

// Function to dynamically add the Authorization header to the configuration
const createApiConfig = () => {
    return new Configuration({
        basePath: process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000', // Use .env or fallback to localhost
        headers: {
            Authorization: `Bearer ${LocalStorageUtil.getToken()}`, // Inject the JWT token from local storage
        },
    })
}

// Function to handle 401 responses globally
const handleUnauthorized = async () => {
    // if user doesn't have a token redirect to login
    if (!LocalStorageUtil.getToken()) {
        window.location.href = '/login'
    } else if (!LocalStorageUtil.getRefreshToken()) {
        window.location.href = '/login'
    } else {
        await refreshTokenHandler()
    }
}

const handleForbidden = async () => {
    if (!LocalStorageUtil.getToken()) {
        window.location.href = '/login'
    } else if (!LocalStorageUtil.getRefreshToken()) {
        window.location.href = '/login'
    } else if (LocalStorageUtil.getRefreshToken()) {
        await refreshTokenHandler()
    }
}

const refreshTokenHandler = async () => {
    refreshToken(LocalStorageUtil.getRefreshToken()!).then(
        (data) => {
            LocalStorageUtil.setToken(data.jwtToken)
            window.location.reload()
        },
        (error) => {
            if (
                error.response?.status === 401 ||
                error.response?.status === 403
            ) {
                window.location.href = '/login'
            }
        }
    )
}

// Wrap API client with custom logic for intercepting responses
const createApiClient = () => {
    const api = new DefaultApi(createApiConfig())

    // Proxy the original request to add interceptor behavior
    const proxyHandler = {
        get(target: any, prop: string) {
            if (typeof target[prop] === 'function') {
                return async (...args: any[]) => {
                    try {
                        // Execute the original API method
                        return await target[prop](...args)
                    } catch (error: any) {
                        if (error.response?.status === 401) {
                            await handleUnauthorized() // Handle 401 errors
                        }
                        if (error.response?.status === 403) {
                            await handleForbidden()
                        }
                        throw error // Re-throw the error for further handling
                    }
                }
            }
            return target[prop] // Return other properties unchanged
        },
    }

    // Return the API client wrapped with the proxy
    return new Proxy(api, proxyHandler)
}

// API client instance
const apiClient = createApiClient()

export default apiClient
