import Card from '../atoms/Card'
import { ArticleItem, ReviewItem } from '../../api/openapi'

export const ArticleCard = ({
    article,
    onClick,
    keywords,
}: {
    article: ArticleItem | ReviewItem
    onClick?: () => void
    keywords: string[]
}) => {
    // Function to highlight keywords in the abstract
    const highlightKeywords = (text: string) => {
        if (!keywords.length) return text

        // Escape special characters for the regex
        const escapedKeywords = keywords.map((kw) =>
            kw.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        )
        const regex = new RegExp(`(${escapedKeywords.join('|')})`, 'gi')

        // Split text by the regex and wrap matches in a <span>
        return text.split(regex).map((part, index) =>
            escapedKeywords.some(
                (kw) => kw.toLowerCase() === part.toLowerCase()
            ) ? (
                <span key={index} className="bg-yellow bg-opacity-40">
                    {part}
                </span>
            ) : (
                part
            )
        )
    }

    return (
        <Card>
            {/*be careful with height and overflow-y-auto in the div below*/}
            <div
                className={`flex flex-col gap-4 px-3 py-2 text-veryDarkGray dark:text-veryLightGray max-h-[66vh]`}
            >
                <div className={`text-base font-semibold text-left`}>
                    {article.title}
                </div>

                <span>
                    {[
                        {
                            key: 'Author:',
                            value: article
                                .authors!.replace(/[\[\]']/g, '') // Remove [, ], and '
                                .split(',') // Split the string by commas into an array
                                .join(', '),
                        },
                        { key: 'DOI:', value: article.dOI },
                        {
                            key: 'PMCID:',
                            value: article.pMCID,
                        },
                        {
                            key: 'Journal (ISSN):',
                            value: article.journalISSN,
                        },
                    ].map((tag, index) => (
                        <span key={index} className="text-sm">
                            <span className={'font-bold'}>{tag.key}</span>{' '}
                            {tag.value} {index < 3 && ' | '}
                        </span>
                    ))}
                </span>

                <div className="text-sm">
                    <div className={`font-bold`}>Abstract:</div>
                    <div>{highlightKeywords(article._abstract || '')}</div>
                </div>
            </div>
        </Card>
    )
}
