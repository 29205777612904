import Card from '../../components/atoms/Card'
import React, { useEffect } from 'react'
import { CustomButton } from '../../components/buttons/CustomButton'
import DynamicListManager from '../../components/molecules/DynamicListManager'
import { useNavigate, useParams } from 'react-router-dom'
import { ProtocolHistorySection } from '../../features/history-section/protocol/ProtocolHistorySection'
import CommentSection from '../../features/comment-section/CommentSection'
import { type FileEnum, StageEnum, TextHistoryItem } from '../../api/openapi'
import { useFetchOnDemand } from '../../hooks/useFetchOnDemand'
import { FunctionButtonsColumn } from '../../components/molecules/FunctionButtonsColumn'
import { updateProjectProtocolId } from '../../middleware/stateService'
import {
    generateProtocol,
    getFullText,
    getFullTextFile,
} from '../../middleware/protocolsService'
import Modal from '../../components/atoms/Modal'

export const ProtocolGeneration = () => {
    const navigate = useNavigate()
    const { projectId } = useParams<{ projectId: string }>()
    const { ideaId } = useParams<{ ideaId: string }>()
    const { protocolId } = useParams<{ protocolId: string }>()
    const [protocolGenerated, setProtocolGenerated] = React.useState(false)
    const [showModal, setShowModal] = React.useState(false)

    const {
        fetchData: getProtocol,
        data: protocol,
        error: getProtocolError,
        loading: getProtocolLoading,
        clearData: clearProtocol,
    } = useFetchOnDemand(() => getFullText(protocolId!))
    const {
        fetchData: fetchGenerateProtocol,
        error: generateProtocolError,
        loading: loadingGenerateProtocol,
    } = useFetchOnDemand(() =>
        generateProtocol(
            projectId!,
            ideaId!,
            authors.join(','),
            institutions.join(',')
        )
    )
    const { fetchData: getFileCall, loading: loadingGetFile } =
        useFetchOnDemand((format: FileEnum) => {
            return getFullTextFile(protocolId!, format)
        })

    const [institutions, setInstitutions] = React.useState<string[]>([])
    const [authors, setAuthors] = React.useState<string[]>([])

    const handleGenerateProtocol = () => {
        fetchGenerateProtocol().then((data) => {
            setProtocolGenerated(true)
        })
    }

    useEffect(() => {
        if (protocolId) {
            setProtocolGenerated(true)
            getProtocol().then(() => {})
        }
    }, [])

    useEffect(() => {
        if (protocolId) {
            setProtocolGenerated(true)
            clearProtocol()
            getProtocol().then(() => {})
        }
    }, [protocolId])

    const handleProtocolClick = (protocol: TextHistoryItem) => {
        navigate(
            `/wizard/${projectId}/protocol-generation/${ideaId}/${protocol.id}`
        )
    }

    const handleDocumentDownload = (format: FileEnum) => {
        getFileCall(format).then((data?: Blob) => {
            if (!data) return
            const url = window.URL.createObjectURL(data)
            const a = document.createElement('a')
            a.href = url
            a.download = `protocol.${format}`
            // Append the <a> element to the document body (required for Firefox)
            document.body.appendChild(a)

            // Trigger click on the <a> element
            a.click()

            // Clean up: Remove the <a> element and revoke the blob URL
            document.body.removeChild(a)
            window.URL.revokeObjectURL(url)

            setShowModal(false)
        })
    }

    return (
        <div className={'flex flex-col items-center gap-6 wizard-width'}>
            {protocol && (
                <Card>
                    <div
                        className={'p-4'}
                        dangerouslySetInnerHTML={{
                            __html: protocol.fulltext,
                        }}
                    />
                </Card>
            )}

            {!protocolGenerated && (
                <div className={'flex flex-row items-start gap-8 relative'}>
                    <Card>
                        <div className="flex flex-col items-left gap-8">
                            <div className="flex flex-col items-left gap-0.5 rounded-md">
                                <div
                                    className={`text-base text-blue font-bold`}
                                >
                                    Title selected
                                </div>
                                <Card backgroundColor={'lightGray'}>
                                    <div
                                        className={`text-sm font-bold text-veryDarkGray dark:text-veryLightGray`}
                                    >
                                        Systematic review: Effectiveness of
                                        virtual care for chronic respiratory
                                        diseases - Patient and provider
                                        perspectives on quality, access and
                                        sustainability
                                    </div>
                                </Card>
                            </div>

                            <Card backgroundColor={'lightGray'}>
                                <div className="flex flex-col items-left gap-5">
                                    <div className="flex flex-col items-left gap-0.5">
                                        <div
                                            className={`text-base text-blue font-medium`}
                                        >
                                            Authors
                                        </div>
                                        <DynamicListManager
                                            onListChange={setAuthors}
                                            placeholder={'Insert author'}
                                            backgroundColor={'white'}
                                        />
                                    </div>
                                    <div className="flex flex-col items-left gap-0.5">
                                        <div
                                            className={`text-base text-blue font-medium`}
                                        >
                                            Organizations / institutions
                                        </div>
                                        <DynamicListManager
                                            onListChange={setInstitutions}
                                            placeholder={'Insert institution'}
                                            backgroundColor={'white'}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </Card>
                    <FunctionButtonsColumn
                        projectId={projectId!}
                        onShowHistoryClick={() => {}}
                    />
                </div>
            )}

            {!protocolGenerated && (
                <div className={`flex self-end`}>
                    <CustomButton
                        loading={loadingGenerateProtocol}
                        text={'Generate Protocol!'}
                        onClick={handleGenerateProtocol}
                    />
                </div>
            )}

            {protocol && (
                <div className={`flex flex-row gap-2 self-end`}>
                    <CustomButton
                        text={'Regenerate'}
                        onClick={handleGenerateProtocol}
                        backgroundColor={'blue'}
                        textColor={'white'}
                    />
                    <CustomButton
                        text={'Download'}
                        onClick={() => setShowModal(true)}
                    />
                </div>
            )}

            {getProtocolLoading && (
                <div className={'text-veryDarkGray dark:text-veryLightGray'}>
                    Loading...
                </div>
            )}
            {getProtocolError && (
                <div className={'text-veryDarkGray dark:text-veryLightGray'}>
                    Error: {getProtocolError}
                </div>
            )}

            <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                <Card>
                    <div className={'flex flex-col gap-4 p-4 items-center'}>
                        <div className={'text-lg font-bold text-blue'}>
                            Download Protocol
                        </div>

                        <div className={'flex flex-row gap-4'}>
                            <CustomButton
                                text={'PDF'}
                                onClick={() => {
                                    handleDocumentDownload('pdf')
                                }}
                            />
                            <CustomButton
                                text={'DOCX'}
                                onClick={() => {
                                    handleDocumentDownload('docx')
                                }}
                            />
                        </div>
                    </div>
                </Card>
            </Modal>

            {/*<ProtocolHistorySection*/}
            {/*    projectId={projectId!}*/}
            {/*    onProtocolClick={handleProtocolClick}*/}
            {/*/>*/}
            <CommentSection projectId={projectId!} stage={StageEnum.Protocol} />
        </div>
    )
}
