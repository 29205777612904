import ArrowButton from '../buttons/ArrowButton'

export const ArrowToggleButton = ({
    value,
    toggle,
    label,
    disabled,
}: {
    value: boolean
    toggle: () => void
    label: string
    disabled?: boolean
}) => {
    return (
        <div
            className={`flex flex-row gap-2 items-center cursor-pointer ${disabled ? 'opacity-50' : ''}`}
            onClick={() => toggle()}
        >
            <div
                className={`text-blue text-base font-medium ${disabled ? 'cursor-not-allowed' : ''}`}
            >
                {label}
            </div>
            <div className={`${disabled ? 'cursor-not-allowed' : ''}`}>
                <ArrowButton
                    iconSize={4}
                    backgroundColor={'yellow'}
                    direction={value ? 'up' : 'down'}
                    onClick={() => toggle()}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}
