/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FetchFulltextResponse
 */
export interface FetchFulltextResponse {
    /**
     * Full-text ID
     * @type {string}
     * @memberof FetchFulltextResponse
     */
    fulltextId: string;
    /**
     * Processed full text content
     * @type {string}
     * @memberof FetchFulltextResponse
     */
    fulltext: string;
    /**
     * Raw full text content
     * @type {string}
     * @memberof FetchFulltextResponse
     */
    rawFulltext: string;
}

/**
 * Check if a given object implements the FetchFulltextResponse interface.
 */
export function instanceOfFetchFulltextResponse(value: object): value is FetchFulltextResponse {
    if (!('fulltextId' in value) || value['fulltextId'] === undefined) return false;
    if (!('fulltext' in value) || value['fulltext'] === undefined) return false;
    if (!('rawFulltext' in value) || value['rawFulltext'] === undefined) return false;
    return true;
}

export function FetchFulltextResponseFromJSON(json: any): FetchFulltextResponse {
    return FetchFulltextResponseFromJSONTyped(json, false);
}

export function FetchFulltextResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FetchFulltextResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'fulltextId': json['fulltext_id'],
        'fulltext': json['fulltext'],
        'rawFulltext': json['raw_fulltext'],
    };
}

export function FetchFulltextResponseToJSON(value?: FetchFulltextResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'fulltext_id': value['fulltextId'],
        'fulltext': value['fulltext'],
        'raw_fulltext': value['rawFulltext'],
    };
}

