import Card from '../atoms/Card'
import { Tooltip } from 'react-tooltip'
import React, { useEffect, useState } from 'react'
import CustomInputField from '../atoms/CustomInputField'
import { CustomButton } from '../buttons/CustomButton'
import { UserProfileOutput } from '../../api/openapi'
import CustomCheckbox from '../atoms/CustomCheckbox'
import { useFetchOnDemand } from '../../hooks/useFetchOnDemand'
import {
    getUserProfile,
    updateUserProfile,
} from '../../middleware/profileService'
import { useFetch } from '../../hooks/useFetch'
import Modal from '../atoms/Modal'
import ToggleButtonGroup from '../atoms/ToggleButtonGroup'
import { IoMdInformationCircle } from 'react-icons/io'

export const UserProfileCard = ({ onClose }: { onClose: () => void }) => {
    const [name, setName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [industry, setIndustry] = useState<string>('')
    const [jobTitle, setJobTitle] = useState<string>('')
    const [company, setCompany] = useState<string>('')
    const [country, setCountry] = useState<string>('')
    const [pubMedKey, setPubMedKey] = useState<string>('')
    const [communication, setCommunication] = useState<boolean>(false)

    const handleThemeToggle = (selected: string) => {}

    const { data, loading, error } = useFetch(getUserProfile)
    const { fetchData: updateProfile } = useFetchOnDemand(() =>
        updateUserProfile(
            name,
            lastName,
            '',
            company,
            jobTitle,
            industry,
            country,
            communication,
            false,
            pubMedKey || ''
        )
    )

    useEffect(() => {
        if (data) {
            setName(data.firstName)
            setLastName(data.lastName)
            setIndustry(data.industry)
            setJobTitle(data.jobTitle)
            setCompany(data.company)
            setCountry(data.country)
            setPubMedKey(data.pubmedApiKey ?? '')
            setCommunication(data.communications)
        }
    }, [data])

    const handleSave = () => {
        updateProfile().then(() => {
            onClose()
        })
    }

    const handleClose = () => {
        onClose()
    }

    return (
        <Modal isOpen={true} onClose={handleClose}>
            <Card>
                <div
                    className={`flex flex-col gap-3 px-5 pt-4 pb-5 min-w-[600px] w-auto text-veryDarkGray`}
                >
                    <div className={`text-2xl font-bold text-yellow text-left`}>
                        Profile
                    </div>

                    {loading && <div>Loading...</div>}

                    {!loading && data && (
                        <div
                            className={`grid grid-cols-2 gap-x-5 gap-y-2 pb-4`}
                        >
                            <CustomInputField
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                label="Name"
                            />

                            <CustomInputField
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                label="Last Name"
                            />

                            <CustomInputField
                                value={industry}
                                onChange={(e) => setIndustry(e.target.value)}
                                label="Industry"
                            />

                            <CustomInputField
                                value={jobTitle}
                                onChange={(e) => setJobTitle(e.target.value)}
                                label="Position"
                            />

                            <CustomInputField
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                label="Company/Institution"
                            />

                            <CustomInputField
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                label="Country"
                            />
                        </div>
                    )}

                    <div className={`text-2xl font-bold text-yellow text-left`}>
                        General
                    </div>

                    {loading && <div>Loading...</div>}
                    {!loading && data && (
                        <div className={`grid grid-cols-2`}>
                            <div className={`flex flex-col gap-2`}>
                                <label className="mb-2 font-medium text-blue">
                                    Preferred appearance
                                </label>

                                <ToggleButtonGroup
                                    options={['Dark', 'Light']}
                                    onToggle={handleThemeToggle}
                                />

                                <CustomInputField
                                    value={pubMedKey || ''}
                                    onChange={(e) =>
                                        setPubMedKey(e.target.value)
                                    }
                                    label="PubMed API key"
                                    icon={
                                        <IoMdInformationCircle
                                            data-tooltip-id="info-tooltip"
                                            data-tooltip-html={`We need this information to provide you the best experience as possible. </br> 
                                                It just takes 2 minutes and it is completely free! <a href="https://support.nlm.nih.gov/knowledgebase/article/KA-05317/en-us" target="_blank" style="color: #FFAC27;">Instructions</a>`}
                                            className="cursor-pointer"
                                        />
                                    }
                                />

                                {/* Tooltip element */}
                                <Tooltip
                                    id="info-tooltip"
                                    place="right"
                                    openOnClick
                                    clickable
                                    style={{
                                        backgroundColor: '#EBF0F3',
                                        color: '#767676',
                                        border: '1px solid #767676',
                                        boxShadow:
                                            '0px 4px 6px rgba(118, 118, 118, 0.6)',
                                    }}
                                />

                                <label className="mb-2 font-medium text-blue">
                                    Communication
                                </label>

                                <div className={`flex flex-col gap-2`}>
                                    <CustomCheckbox
                                        checked={communication}
                                        onChange={(e) => {
                                            console.log(e)
                                            setCommunication(e)
                                        }}
                                        label="Allow Erudita to send me relevant emails."
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={`flex flex-row gap-2 self-end`}>
                        <CustomButton
                            backgroundColor={'lightGray'}
                            textColor={'blue'}
                            text={'Cancel'}
                            onClick={handleClose}
                        />
                        <CustomButton text={'Save'} onClick={handleSave} />
                    </div>
                </div>
            </Card>
        </Modal>
    )
}
