import React from 'react'
import { colorMapping } from '../../utils/colorMappings'

const Card = ({
    backgroundColor = 'white',
    children,
}: {
    backgroundColor?: string
    children: React.ReactNode
}) => {
    const lightColor = colorMapping[backgroundColor]?.light
    const darkColor = colorMapping[backgroundColor]?.dark

    return (
        <div
            className={`bg-${lightColor} dark:bg-${darkColor}
                        shadow-blueCustomShadow transition-colors duration-100 ease-in
                        border-2 border-blue p-4 rounded-md w-full overflow-y-auto`}
        >
            {children}
        </div>
    )
}

export default Card
