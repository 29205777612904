/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ArticleInfoItem } from './ArticleInfoItem';
import {
    ArticleInfoItemFromJSON,
    ArticleInfoItemFromJSONTyped,
    ArticleInfoItemToJSON,
} from './ArticleInfoItem';

/**
 * 
 * @export
 * @interface ListArticleInfoItem
 */
export interface ListArticleInfoItem {
    /**
     * Articles information
     * @type {Array<ArticleInfoItem>}
     * @memberof ListArticleInfoItem
     */
    articles?: Array<ArticleInfoItem>;
    /**
     * Reviews information
     * @type {Array<ArticleInfoItem>}
     * @memberof ListArticleInfoItem
     */
    reviews?: Array<ArticleInfoItem>;
}

/**
 * Check if a given object implements the ListArticleInfoItem interface.
 */
export function instanceOfListArticleInfoItem(value: object): value is ListArticleInfoItem {
    return true;
}

export function ListArticleInfoItemFromJSON(json: any): ListArticleInfoItem {
    return ListArticleInfoItemFromJSONTyped(json, false);
}

export function ListArticleInfoItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListArticleInfoItem {
    if (json == null) {
        return json;
    }
    return {
        
        'articles': json['articles'] == null ? undefined : ((json['articles'] as Array<any>).map(ArticleInfoItemFromJSON)),
        'reviews': json['reviews'] == null ? undefined : ((json['reviews'] as Array<any>).map(ArticleInfoItemFromJSON)),
    };
}

export function ListArticleInfoItemToJSON(value?: ListArticleInfoItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'articles': value['articles'] == null ? undefined : ((value['articles'] as Array<any>).map(ArticleInfoItemToJSON)),
        'reviews': value['reviews'] == null ? undefined : ((value['reviews'] as Array<any>).map(ArticleInfoItemToJSON)),
    };
}

