/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntroTextResponse
 */
export interface IntroTextResponse {
    /**
     * 
     * @type {number}
     * @memberof IntroTextResponse
     */
    statusCode: number;
    /**
     * Full-text ID
     * @type {string}
     * @memberof IntroTextResponse
     */
    fulltextId: string;
    /**
     * Processed text for SR protocol (including tooltips)
     * @type {string}
     * @memberof IntroTextResponse
     */
    text: string;
    /**
     * Raw text for SR protocol
     * @type {string}
     * @memberof IntroTextResponse
     */
    unprocessedText: string;
}

/**
 * Check if a given object implements the IntroTextResponse interface.
 */
export function instanceOfIntroTextResponse(value: object): value is IntroTextResponse {
    if (!('statusCode' in value) || value['statusCode'] === undefined) return false;
    if (!('fulltextId' in value) || value['fulltextId'] === undefined) return false;
    if (!('text' in value) || value['text'] === undefined) return false;
    if (!('unprocessedText' in value) || value['unprocessedText'] === undefined) return false;
    return true;
}

export function IntroTextResponseFromJSON(json: any): IntroTextResponse {
    return IntroTextResponseFromJSONTyped(json, false);
}

export function IntroTextResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntroTextResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'statusCode': json['status_code'],
        'fulltextId': json['fulltext_id'],
        'text': json['text'],
        'unprocessedText': json['unprocessed_text'],
    };
}

export function IntroTextResponseToJSON(value?: IntroTextResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status_code': value['statusCode'],
        'fulltext_id': value['fulltextId'],
        'text': value['text'],
        'unprocessed_text': value['unprocessedText'],
    };
}

