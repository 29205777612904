/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchHistoryItemShort
 */
export interface SearchHistoryItemShort {
    /**
     * Search ID
     * @type {string}
     * @memberof SearchHistoryItemShort
     */
    id?: string;
    /**
     * User ID
     * @type {string}
     * @memberof SearchHistoryItemShort
     */
    userId?: string;
    /**
     * Timestamp of the search
     * @type {string}
     * @memberof SearchHistoryItemShort
     */
    timestamp?: string;
    /**
     * Original user's query
     * @type {string}
     * @memberof SearchHistoryItemShort
     */
    originalQuery?: string;
    /**
     * Processed query - search string
     * @type {string}
     * @memberof SearchHistoryItemShort
     */
    processedQuery?: string;
    /**
     * Total number of results matching the search
     * @type {number}
     * @memberof SearchHistoryItemShort
     */
    totalItems?: number;
    /**
     * Min year filter associated to the search
     * @type {number}
     * @memberof SearchHistoryItemShort
     */
    minYear?: number;
    /**
     * Max year filter associated to the search
     * @type {number}
     * @memberof SearchHistoryItemShort
     */
    maxYear?: number;
    /**
     * Number of articles associated to the search
     * @type {number}
     * @memberof SearchHistoryItemShort
     */
    numArticlesRetrieved?: number;
    /**
     * Number of reviews associated to the search
     * @type {number}
     * @memberof SearchHistoryItemShort
     */
    numReviewsRetrieved?: number;
}

/**
 * Check if a given object implements the SearchHistoryItemShort interface.
 */
export function instanceOfSearchHistoryItemShort(value: object): value is SearchHistoryItemShort {
    return true;
}

export function SearchHistoryItemShortFromJSON(json: any): SearchHistoryItemShort {
    return SearchHistoryItemShortFromJSONTyped(json, false);
}

export function SearchHistoryItemShortFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchHistoryItemShort {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'userId': json['user_id'] == null ? undefined : json['user_id'],
        'timestamp': json['timestamp'] == null ? undefined : json['timestamp'],
        'originalQuery': json['original_query'] == null ? undefined : json['original_query'],
        'processedQuery': json['processed_query'] == null ? undefined : json['processed_query'],
        'totalItems': json['total_items'] == null ? undefined : json['total_items'],
        'minYear': json['min_year'] == null ? undefined : json['min_year'],
        'maxYear': json['max_year'] == null ? undefined : json['max_year'],
        'numArticlesRetrieved': json['num_articles_retrieved'] == null ? undefined : json['num_articles_retrieved'],
        'numReviewsRetrieved': json['num_reviews_retrieved'] == null ? undefined : json['num_reviews_retrieved'],
    };
}

export function SearchHistoryItemShortToJSON(value?: SearchHistoryItemShort | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'user_id': value['userId'],
        'timestamp': value['timestamp'],
        'original_query': value['originalQuery'],
        'processed_query': value['processedQuery'],
        'total_items': value['totalItems'],
        'min_year': value['minYear'],
        'max_year': value['maxYear'],
        'num_articles_retrieved': value['numArticlesRetrieved'],
        'num_reviews_retrieved': value['numReviewsRetrieved'],
    };
}

