/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TextHistoryItem } from './TextHistoryItem';
import {
    TextHistoryItemFromJSON,
    TextHistoryItemFromJSONTyped,
    TextHistoryItemToJSON,
} from './TextHistoryItem';

/**
 * 
 * @export
 * @interface ListTextHistoryItems
 */
export interface ListTextHistoryItems {
    /**
     * History of protocols
     * @type {Array<TextHistoryItem>}
     * @memberof ListTextHistoryItems
     */
    results?: Array<TextHistoryItem>;
}

/**
 * Check if a given object implements the ListTextHistoryItems interface.
 */
export function instanceOfListTextHistoryItems(value: object): value is ListTextHistoryItems {
    return true;
}

export function ListTextHistoryItemsFromJSON(json: any): ListTextHistoryItems {
    return ListTextHistoryItemsFromJSONTyped(json, false);
}

export function ListTextHistoryItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListTextHistoryItems {
    if (json == null) {
        return json;
    }
    return {
        
        'results': json['results'] == null ? undefined : ((json['results'] as Array<any>).map(TextHistoryItemFromJSON)),
    };
}

export function ListTextHistoryItemsToJSON(value?: ListTextHistoryItems | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'results': value['results'] == null ? undefined : ((value['results'] as Array<any>).map(TextHistoryItemToJSON)),
    };
}

