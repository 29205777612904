interface TitleProps {
    text: string
    className?: string
}

export const Title = ({ text, className }: TitleProps) => {
    return (
        <div className={`text-3xl font-bold text-blue ${className}`}>
            {text}
        </div>
    )
}
