import { LoadingSpinner } from '../atoms/LoadingSpinner'

export const CustomButton = ({
    text,
    onClick,
    isDisabled = false,
    backgroundColor = 'yellow',
    textColor = 'blue',
    loading = false,
    icon,
}: {
    text: string
    onClick: () => void
    isDisabled?: boolean
    backgroundColor?: 'yellow' | 'blue' | 'lightGray'
    textColor?: string
    loading?: boolean
    icon?: React.ReactNode
}) => {
    return (
        <button
            disabled={isDisabled || loading}
            onClick={onClick}
            className={`flex items-center justify-center rounded-md bg-${backgroundColor} text-${textColor} px-4 py-1.5 min-w-[90px] min-h-[39px] font-bold text-lg ${
                isDisabled || loading ? 'cursor-not-allowed' : 'cursor-pointer'
            } transition-transform transform hover:scale-110 ${
                backgroundColor === 'yellow'
                    ? 'shadow-yellowCustomShadow'
                    : backgroundColor === 'blue'
                      ? 'shadow-blueCustomShadow'
                      : 'shadow-grayCustomShadow'
            }`}
        >
            {loading ? (
                <LoadingSpinner />
            ) : (
                <div className={'flex flex-row gap-2'}>
                    <span>{text}</span>
                    {icon}
                </div>
            )}
        </button>
    )
}
