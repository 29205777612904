/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchArticleResponse
 */
export interface SearchArticleResponse {
    /**
     * 
     * @type {number}
     * @memberof SearchArticleResponse
     */
    statusCode: number;
    /**
     * List of DOIs added correctly
     * @type {Array<any>}
     * @memberof SearchArticleResponse
     */
    validDois: Array<any>;
    /**
     * List of DOIs containing errors
     * @type {Array<any>}
     * @memberof SearchArticleResponse
     */
    errorDois: Array<any>;
    /**
     * List of articles found, and their information attached
     * @type {Array<any>}
     * @memberof SearchArticleResponse
     */
    articles: Array<any>;
    /**
     * List of reviews found, and their information attached
     * @type {Array<any>}
     * @memberof SearchArticleResponse
     */
    reviews: Array<any>;
    /**
     * Whether the articles and reviews where saved in the database. Possible values: 0 or 1
     * @type {number}
     * @memberof SearchArticleResponse
     */
    saved: number;
    /**
     * 
     * @type {object}
     * @memberof SearchArticleResponse
     */
    modelConfig?: object;
}

/**
 * Check if a given object implements the SearchArticleResponse interface.
 */
export function instanceOfSearchArticleResponse(value: object): value is SearchArticleResponse {
    if (!('statusCode' in value) || value['statusCode'] === undefined) return false;
    if (!('validDois' in value) || value['validDois'] === undefined) return false;
    if (!('errorDois' in value) || value['errorDois'] === undefined) return false;
    if (!('articles' in value) || value['articles'] === undefined) return false;
    if (!('reviews' in value) || value['reviews'] === undefined) return false;
    if (!('saved' in value) || value['saved'] === undefined) return false;
    return true;
}

export function SearchArticleResponseFromJSON(json: any): SearchArticleResponse {
    return SearchArticleResponseFromJSONTyped(json, false);
}

export function SearchArticleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchArticleResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'statusCode': json['status_code'],
        'validDois': json['valid_dois'],
        'errorDois': json['error_dois'],
        'articles': json['articles'],
        'reviews': json['reviews'],
        'saved': json['saved'],
        'modelConfig': json['model_config'] == null ? undefined : json['model_config'],
    };
}

export function SearchArticleResponseToJSON(value?: SearchArticleResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status_code': value['statusCode'],
        'valid_dois': value['validDois'],
        'error_dois': value['errorDois'],
        'articles': value['articles'],
        'reviews': value['reviews'],
        'saved': value['saved'],
        'model_config': value['modelConfig'],
    };
}

