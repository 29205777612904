/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TextHistoryItem
 */
export interface TextHistoryItem {
    /**
     * Search ID
     * @type {string}
     * @memberof TextHistoryItem
     */
    id: string;
    /**
     * User ID
     * @type {string}
     * @memberof TextHistoryItem
     */
    userId: string;
    /**
     * Timestamp of the text generation
     * @type {string}
     * @memberof TextHistoryItem
     */
    timestamp: string;
    /**
     * ID of the idea associated
     * @type {string}
     * @memberof TextHistoryItem
     */
    ideaId: string;
    /**
     * Path to the processed content - including tooltips
     * @type {string}
     * @memberof TextHistoryItem
     */
    filePath: string;
    /**
     * Path to the raw content
     * @type {string}
     * @memberof TextHistoryItem
     */
    rawFilePath: string;
    /**
     * Title of the text
     * @type {string}
     * @memberof TextHistoryItem
     */
    title: string;
    /**
     * List article ids associated to the text
     * @type {Array<any>}
     * @memberof TextHistoryItem
     */
    articlesIds: Array<any>;
    /**
     * List review ids associated to the text
     * @type {Array<any>}
     * @memberof TextHistoryItem
     */
    reviewsIds: Array<any>;
}

/**
 * Check if a given object implements the TextHistoryItem interface.
 */
export function instanceOfTextHistoryItem(value: object): value is TextHistoryItem {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    if (!('ideaId' in value) || value['ideaId'] === undefined) return false;
    if (!('filePath' in value) || value['filePath'] === undefined) return false;
    if (!('rawFilePath' in value) || value['rawFilePath'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('articlesIds' in value) || value['articlesIds'] === undefined) return false;
    if (!('reviewsIds' in value) || value['reviewsIds'] === undefined) return false;
    return true;
}

export function TextHistoryItemFromJSON(json: any): TextHistoryItem {
    return TextHistoryItemFromJSONTyped(json, false);
}

export function TextHistoryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextHistoryItem {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': json['user_id'],
        'timestamp': json['timestamp'],
        'ideaId': json['idea_id'],
        'filePath': json['file_path'],
        'rawFilePath': json['raw_file_path'],
        'title': json['title'],
        'articlesIds': json['articles_ids'],
        'reviewsIds': json['reviews_ids'],
    };
}

export function TextHistoryItemToJSON(value?: TextHistoryItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'user_id': value['userId'],
        'timestamp': value['timestamp'],
        'idea_id': value['ideaId'],
        'file_path': value['filePath'],
        'raw_file_path': value['rawFilePath'],
        'title': value['title'],
        'articles_ids': value['articlesIds'],
        'reviews_ids': value['reviewsIds'],
    };
}

