import Card from '../atoms/Card'
import React, { useEffect, useState } from 'react'
import { ArticleItem, ReviewItem } from '../../api/openapi'
import { colorMapping } from '../../utils/colorMappings'

export const ArticlesListCard = ({
    articles,
    onClick,
    backgroundColor,
    disableSelect,
}: {
    articles: ArticleItem[] | ReviewItem[]
    onClick?: (doi: string) => void
    backgroundColor: string
    disableSelect: boolean
}) => {
    const lightColor = colorMapping[backgroundColor]?.light
    const darkColor = colorMapping[backgroundColor]?.dark
    const [selected, setSelected] = useState<string>('')

    useEffect(() => {
        if (articles.length > 0) {
            setSelected(articles[0].dOI!)
            if (onClick) onClick(articles[0].dOI!)
        }
    }, [articles])

    const onSelect = (doi: string) => {
        if (selected === doi) return
        setSelected(doi)
        if (onClick) onClick(doi)
    }

    return (
        <Card backgroundColor={backgroundColor}>
            {/*be careful with height and overflow-y-auto in the div below*/}
            <div
                className={`flex flex-col gap-4 bg-${lightColor} dark:bg-${darkColor} w-full max-h-[60vh]`}
            >
                {articles.map((article: ArticleItem, index) => (
                    <div
                        key={article.pubmedID}
                        className={`flex flex-col gap-2 ${disableSelect ? '' : 'cursor-pointer'} ${selected === article.dOI && !disableSelect ? 'text-blue dark:text-yellow' : 'text-darkGray dark:text-veryLightGray'}`}
                        onClick={() => onSelect(article.dOI!)}
                    >
                        <div className={`text-base font-bold text-left`}>
                            {article.title}
                        </div>

                        <div className={`text-sm text-left`}>
                            {article
                                .authors!.replace(/[\[\]']/g, '') // Remove [, ], and '
                                .split(',') // Split the string by commas into an array
                                .join(', ')}{' '}
                        </div>

                        {/* Horizontal line */}
                        {index !== articles.length - 1 && (
                            <div className={`border-b border-yellow`} />
                        )}
                    </div>
                ))}
            </div>
        </Card>
    )
}
