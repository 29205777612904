/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArticleInfoItem
 */
export interface ArticleInfoItem {
    /**
     * Pubmed ID
     * @type {string}
     * @memberof ArticleInfoItem
     */
    pmid?: string;
    /**
     * Pubmed Central ID
     * @type {string}
     * @memberof ArticleInfoItem
     */
    pmcid?: string;
    /**
     * Title of the article
     * @type {string}
     * @memberof ArticleInfoItem
     */
    title?: string;
    /**
     * Target population of the article
     * @type {string}
     * @memberof ArticleInfoItem
     */
    population?: string;
    /**
     * Target intervention of the article
     * @type {string}
     * @memberof ArticleInfoItem
     */
    intervention?: string;
    /**
     * Target outcome of the article
     * @type {string}
     * @memberof ArticleInfoItem
     */
    outcome?: string;
    /**
     * Results of the article
     * @type {string}
     * @memberof ArticleInfoItem
     */
    results?: string;
    /**
     * Conclusions of the article
     * @type {string}
     * @memberof ArticleInfoItem
     */
    conclusions?: string;
    /**
     * Journal name
     * @type {string}
     * @memberof ArticleInfoItem
     */
    journalName?: string;
    /**
     * Journal ISSN
     * @type {string}
     * @memberof ArticleInfoItem
     */
    journalIssn?: string;
    /**
     * Journal Impact Factor 2022
     * @type {string}
     * @memberof ArticleInfoItem
     */
    jIF2022?: string;
}

/**
 * Check if a given object implements the ArticleInfoItem interface.
 */
export function instanceOfArticleInfoItem(value: object): value is ArticleInfoItem {
    return true;
}

export function ArticleInfoItemFromJSON(json: any): ArticleInfoItem {
    return ArticleInfoItemFromJSONTyped(json, false);
}

export function ArticleInfoItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleInfoItem {
    if (json == null) {
        return json;
    }
    return {
        
        'pmid': json['pmid'] == null ? undefined : json['pmid'],
        'pmcid': json['pmcid'] == null ? undefined : json['pmcid'],
        'title': json['title'] == null ? undefined : json['title'],
        'population': json['population'] == null ? undefined : json['population'],
        'intervention': json['intervention'] == null ? undefined : json['intervention'],
        'outcome': json['outcome'] == null ? undefined : json['outcome'],
        'results': json['results'] == null ? undefined : json['results'],
        'conclusions': json['conclusions'] == null ? undefined : json['conclusions'],
        'journalName': json['journal_name'] == null ? undefined : json['journal_name'],
        'journalIssn': json['journal_issn'] == null ? undefined : json['journal_issn'],
        'jIF2022': json['JIF2022'] == null ? undefined : json['JIF2022'],
    };
}

export function ArticleInfoItemToJSON(value?: ArticleInfoItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pmid': value['pmid'],
        'pmcid': value['pmcid'],
        'title': value['title'],
        'population': value['population'],
        'intervention': value['intervention'],
        'outcome': value['outcome'],
        'results': value['results'],
        'conclusions': value['conclusions'],
        'journal_name': value['journalName'],
        'journal_issn': value['journalIssn'],
        'JIF2022': value['jIF2022'],
    };
}

