import { useEffect, useState } from 'react'
import CommentCard from './CommentCard'
import CustomInputField from '../../components/atoms/CustomInputField'
import { getMessages, sendMessage } from '../../middleware/messagesService'
import { useFetchOnDemand } from '../../hooks/useFetchOnDemand'
import { StageEnum } from '../../api/openapi'
import { IconButton } from '../../components/buttons/IconButton'
import { IoMdSend } from 'react-icons/io'
import LocalStorageUtil from '../../utils/LocalStorageUtil'

const CommentSection = ({
    projectId,
    stage,
}: {
    projectId: string
    stage: StageEnum
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [comment, setComment] = useState('')

    const {
        fetchData: getComments,
        data: comments,
        clearData,
        loading,
        error,
    } = useFetchOnDemand(
        () => {
            return getMessages(projectId)
        },
        (data) => {
            switch (stage) {
                case StageEnum.Ideas:
                    return data.ideas
                case StageEnum.Protocol:
                    return data.protocol
                case StageEnum.Search:
                    return data.search
            }
        }
    )

    useEffect(() => {
        if (isOpen) {
            getComments().then(() => console.log('Comments fetched'))
        } else {
            clearData()
        }
    }, [isOpen])

    const toggleCommentSection = () => {
        setIsOpen(!isOpen)
    }

    const onSend = () => {
        sendMessage(projectId, stage, comment).then(() => {
            setComment('')
            getComments().then(() => console.log('Comments fetched'))
        })
    }

    return (
        <div>
            {/* Comment Section */}
            <div
                className={`fixed right-0 top-[310px] -translate-y-[160px] bg-white dark:bg-bgBlack border-2 border-blue w-80 h-[calc(100%-230px)]
                    rounded-md shadow-md shadow-blue
                    transition-transform duration-300 transform ${isOpen ? 'translate-x-0 z-10' : 'translate-x-full'}`}
            >
                <div
                    className={`absolute top-1/3 left-[-40px] transform -translate-y-1/2 bg-white dark:bg-bgBlack text-white
                        cursor-pointer w-[40px] h-[90px] flex items-center justify-center border-2 border-blue rounded-l-lg transition-transform duration-300 ${
                            isOpen ? 'translate-x-0' : 'translate-x-[5px]'
                        }`}
                    onClick={toggleCommentSection}
                >
                    <span className="block -rotate-90 whitespace-nowrap text-blue text-sm font-bold bg-white dark:bg-bgBlack">
                        Comments
                    </span>
                </div>

                {/* Comments Content */}
                <div className="flex flex-col gap-2 p-3 h-[calc(100%-170px)] overflow-y-auto">
                    {loading && (
                        <div
                            className={'text-darkGray dark:text-veryLightGray'}
                        >
                            Loading...
                        </div>
                    )}
                    {error && (
                        <div
                            className={'text-darkGray dark:text-veryLightGray'}
                        >
                            Error loading comments.
                        </div>
                    )}
                    {comments &&
                        !loading &&
                        comments.map((comment, index) => (
                            <CommentCard
                                isMine={
                                    comment.author ===
                                    LocalStorageUtil.getEmail()
                                }
                                comment={comment}
                                key={index}
                            />
                        ))}
                    {comments && comments.length === 0 && (
                        <div
                            className={
                                'dark:text-veryLightGray text-veryDarkGray'
                            }
                        >
                            No comments yet
                        </div>
                    )}
                </div>

                {/* Input Area */}
                <div className="absolute bottom-0 left-0 w-full p-3 bg-white dark:bg-bgBlack">
                    <div className="flex flex-row items-center bg-lightGray dark:bg-bgBlack rounded-md p-2">
                        {/* Input Field */}
                        <CustomInputField
                            backgroundColor={'lightGray'}
                            multiline={true}
                            placeholder="Write a comment..."
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            className="flex-1 bg-transparent outline-none p-2" // Ensure it fills the available space
                        />
                        {/* Button */}
                        <IconButton
                            onClick={onSend}
                            icon={<IoMdSend className={`w-6 h-6`} />}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommentSection
