/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntroTextRequestV2
 */
export interface IntroTextRequestV2 {
    /**
     * Project ID
     * @type {string}
     * @memberof IntroTextRequestV2
     */
    projectId: string;
    /**
     * ID of the idea selected
     * @type {string}
     * @memberof IntroTextRequestV2
     */
    selectedIdeaId: string;
    /**
     * Authors (comma separated values)
     * @type {string}
     * @memberof IntroTextRequestV2
     */
    authors: string;
    /**
     * Institutions (comma separated values)
     * @type {string}
     * @memberof IntroTextRequestV2
     */
    institutions: string;
    /**
     * Timestamp when the endpoint call was made
     * @type {string}
     * @memberof IntroTextRequestV2
     */
    timestamp: string;
}

/**
 * Check if a given object implements the IntroTextRequestV2 interface.
 */
export function instanceOfIntroTextRequestV2(value: object): value is IntroTextRequestV2 {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('selectedIdeaId' in value) || value['selectedIdeaId'] === undefined) return false;
    if (!('authors' in value) || value['authors'] === undefined) return false;
    if (!('institutions' in value) || value['institutions'] === undefined) return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    return true;
}

export function IntroTextRequestV2FromJSON(json: any): IntroTextRequestV2 {
    return IntroTextRequestV2FromJSONTyped(json, false);
}

export function IntroTextRequestV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): IntroTextRequestV2 {
    if (json == null) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'selectedIdeaId': json['selected_idea_id'],
        'authors': json['authors'],
        'institutions': json['institutions'],
        'timestamp': json['timestamp'],
    };
}

export function IntroTextRequestV2ToJSON(value?: IntroTextRequestV2 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'project_id': value['projectId'],
        'selected_idea_id': value['selectedIdeaId'],
        'authors': value['authors'],
        'institutions': value['institutions'],
        'timestamp': value['timestamp'],
    };
}

