import apiClient from '../api/apiClient'
import type {
    AuthorisationResponse,
    RefreshTokenResponse,
} from '../api/openapi'

export const googleAuthorization = (
    code: string
): Promise<AuthorisationResponse> => {
    return apiClient.googleAuthGoogleAuthorisePost({
        authorisation: {
            code,
            redirectUri: 'postmessage',
        },
    })
}

export const microsoftAuthorization = (
    code: string
): Promise<AuthorisationResponse> => {
    return apiClient.microsoftAuthMicrosoftAuthorisePost({
        authorisation: {
            code,
            redirectUri: 'postmessage',
        },
    })
}

export const linkedInAuthorization = (
    code: string
): Promise<AuthorisationResponse> => {
    return apiClient.linkedinAuthLinkedinAuthorisePost({
        authorisation: {
            code,
            redirectUri: 'http://localhost:3000',
        },
    })
}

export const refreshToken = (
    refreshToken: string
): Promise<RefreshTokenResponse> => {
    return apiClient.refreshAccessTokenTokenRefreshPost({
        refreshToken,
    })
}
