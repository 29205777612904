import apiClient from '../api/apiClient'
import type {
    ListIdeaHistoryItems,
    ListSearchHistoryItems,
    ListTextHistoryItems,
    UserHistoryV2,
} from '../api/openapi'

export const getLiteratureSearchHistory = (
    projectId: string
): Promise<ListSearchHistoryItems> => {
    return apiClient.fetchUserSearchHistoryV2FetchSearchHistoryV2Post({
        fetchHistory: {
            projectId,
        },
    })
}

export const getIdeasHistory = (
    projectId: string
): Promise<ListIdeaHistoryItems> => {
    return apiClient.fetchUserIdeaHistoryV2FetchIdeaHistoryV2Post({
        fetchHistory: {
            projectId,
        },
    })
}

export const getProtocolHistory = (
    projectId: string
): Promise<ListTextHistoryItems> => {
    return apiClient.fetchUserTextHistoryV2FetchTextHistoryV2Post({
        fetchHistory: {
            projectId,
        },
    })
}

export const getHistory = (projectId: string): Promise<UserHistoryV2> => {
    return apiClient.fetchUserHistoryFetchHistoryV2Post({
        fetchHistory: {
            projectId,
        },
    })
}
