import { Outlet } from 'react-router-dom'
import { WizardProgressBar } from '../../components/molecules/WizardProgressBar'
import { WizardProvider } from '../../context/WizardContext'

export const Wizard = () => {
    return (
        <WizardProvider>
            <div
                className={
                    'flex flex-col items-center gap-8 px-2 md:px-4 xl:px-8'
                }
            >
                {/* Other layout or state logic for the wizard */}
                <WizardProgressBar />
                <Outlet /> {/* This will render the sub-routes */}
            </div>
        </WizardProvider>
    )
}
