import Card from '../atoms/Card'
import { IdeaItem } from '../../api/openapi'
import { useData } from '../../context/WizardContext'

export const IdeaCard = ({
    idea,
    selected,
    onClick,
}: {
    idea: IdeaItem
    selected: string
    onClick: (idea: IdeaItem) => void
}) => {
    const { isOwner } = useData()

    return (
        <Card>
            <div
                onClick={() => isOwner && onClick(idea)}
                key={idea.id}
                className={`flex flex-col gap-2 ${isOwner ? 'cursor-pointer' : 'cursor-default'}`}
            >
                <div
                    className={`text-lg font-bold text-left ${selected === idea.id ? 'text-blue dark:text-yellow' : 'text-darkGray dark:text-darkGray'}`}
                >
                    {idea.title}
                </div>

                <div
                    className={`flex flex-col gap-1 ${selected === idea.id ? 'text-blue dark:text-veryLightGray' : 'text-darkGray'}`}
                >
                    {[
                        {
                            label: 'Target outcome:',
                            value: idea.targetOutcome,
                        },
                        {
                            label: 'Last review found on this topic:',
                            value: idea.lastReview?.title,
                        },
                        {
                            label: 'Relevant clinical trials found:',
                            value: idea.clinicalTrials?.length,
                        },
                        {
                            label: 'Suggested publication journal (JIF 2022):',
                            value: idea.potentialJournals?.[0].journalName,
                        },
                    ].map((item, index) => (
                        <span className={`text-sm text-left`} key={index}>
                            <span className={`font-semibold`}>
                                {item.label}
                            </span>{' '}
                            {item.value}
                        </span>
                    ))}
                </div>
            </div>
        </Card>
    )
}
