/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Message } from './Message';
import {
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
} from './Message';

/**
 * 
 * @export
 * @interface ListMessages
 */
export interface ListMessages {
    /**
     * List of messages associated to the search stage
     * @type {Array<Message>}
     * @memberof ListMessages
     */
    search?: Array<Message>;
    /**
     * List of messages associated to the ideas generation stage
     * @type {Array<Message>}
     * @memberof ListMessages
     */
    ideas?: Array<Message>;
    /**
     * List of messages associated to the protocol generation stage
     * @type {Array<Message>}
     * @memberof ListMessages
     */
    protocol?: Array<Message>;
}

/**
 * Check if a given object implements the ListMessages interface.
 */
export function instanceOfListMessages(value: object): value is ListMessages {
    return true;
}

export function ListMessagesFromJSON(json: any): ListMessages {
    return ListMessagesFromJSONTyped(json, false);
}

export function ListMessagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListMessages {
    if (json == null) {
        return json;
    }
    return {
        
        'search': json['search'] == null ? undefined : ((json['search'] as Array<any>).map(MessageFromJSON)),
        'ideas': json['ideas'] == null ? undefined : ((json['ideas'] as Array<any>).map(MessageFromJSON)),
        'protocol': json['protocol'] == null ? undefined : ((json['protocol'] as Array<any>).map(MessageFromJSON)),
    };
}

export function ListMessagesToJSON(value?: ListMessages | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'search': value['search'] == null ? undefined : ((value['search'] as Array<any>).map(MessageToJSON)),
        'ideas': value['ideas'] == null ? undefined : ((value['ideas'] as Array<any>).map(MessageToJSON)),
        'protocol': value['protocol'] == null ? undefined : ((value['protocol'] as Array<any>).map(MessageToJSON)),
    };
}

