import React, { useEffect, useState } from 'react'
import CustomInputField from '../atoms/CustomInputField'
import { MinusButton } from '../buttons/MinusButton'
import { colorMapping } from '../../utils/colorMappings'
import { IconButton } from '../buttons/IconButton'
import { FaPlus } from 'react-icons/fa'

interface DynamicListManagerProps {
    placeholder: string
    initialItems?: string[] // Optional initial list
    onListChange: (updatedList: string[]) => void // Callback for list changes
    backgroundColor: string
}

const DynamicListManager = ({
    placeholder,
    initialItems = [], // Default to empty array if not provided
    onListChange,
    backgroundColor,
}: DynamicListManagerProps) => {
    const [inputValue, setInputValue] = useState('')
    const [items, setItems] = useState<string[]>(initialItems)
    const lightColor = colorMapping[backgroundColor]?.light
    const darkColor = colorMapping[backgroundColor]?.dark

    // Effect to notify parent of the initial items on mount
    useEffect(() => {
        onListChange(items)
    }, []) // Runs only on mount

    const handleAddItem = () => {
        if (inputValue) {
            const updatedItems = [...items, inputValue]
            setItems(updatedItems)
            setInputValue('') // Clear the input field after adding
            onListChange(updatedItems) // Notify the parent of the list change
        }
    }

    const handleRemoveItem = (indexToRemove: number) => {
        const updatedItems = items.filter((_, index) => index !== indexToRemove)
        setItems(updatedItems)
        onListChange(updatedItems) // Notify the parent of the list change
    }

    return (
        <div className={'flex flex-row gap-2'}>
            <div className={'flex flex-row items-center gap-2'}>
                <IconButton
                    onClick={handleAddItem}
                    icon={<FaPlus className={`w-3 h-3`} />}
                />
                <CustomInputField
                    backgroundColor={'white'}
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />
            </div>

            <div className={'flex flex-row items-center gap-2'}>
                {items.map((item, index) => (
                    <div
                        key={index}
                        className={`flex flex-row p-2 bg-${lightColor} dark:bg-${darkColor} items-center gap-3 rounded-md`}
                    >
                        <span className={'text-yellow font-bold text-base'}>
                            {item}
                        </span>
                        <MinusButton
                            iconSize={2}
                            onClick={() => handleRemoveItem(index)}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default DynamicListManager
