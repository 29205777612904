/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StageEnum } from './StageEnum';
import {
    StageEnumFromJSON,
    StageEnumFromJSONTyped,
    StageEnumToJSON,
} from './StageEnum';

/**
 * 
 * @export
 * @interface MessageInput
 */
export interface MessageInput {
    /**
     * Project ID
     * @type {string}
     * @memberof MessageInput
     */
    projectId: string;
    /**
     * Project's stage where message belongs to
     * @type {StageEnum}
     * @memberof MessageInput
     */
    stage: StageEnum;
    /**
     * Author's email
     * @type {string}
     * @memberof MessageInput
     */
    author: string;
    /**
     * Message content
     * @type {string}
     * @memberof MessageInput
     */
    message: string;
}



/**
 * Check if a given object implements the MessageInput interface.
 */
export function instanceOfMessageInput(value: object): value is MessageInput {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('stage' in value) || value['stage'] === undefined) return false;
    if (!('author' in value) || value['author'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function MessageInputFromJSON(json: any): MessageInput {
    return MessageInputFromJSONTyped(json, false);
}

export function MessageInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageInput {
    if (json == null) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'stage': StageEnumFromJSON(json['stage']),
        'author': json['author'],
        'message': json['message'],
    };
}

export function MessageInputToJSON(value?: MessageInput | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'project_id': value['projectId'],
        'stage': StageEnumToJSON(value['stage']),
        'author': value['author'],
        'message': value['message'],
    };
}

