import { Message } from '../../api/openapi'

const CommentCard = ({
    isMine,
    comment,
}: {
    isMine: boolean
    comment: Message
}) => {
    return (
        <div
            className={`bg-lightGray dark:bg-darkBlue shadow-${isMine ? 'yellow' : 'blue'} border-2 border-${isMine ? 'yellow' : 'blue'} shadow-md py-1 px-2 rounded-md`}
        >
            <div
                className={`flex flex-col items-start justify-between gap-0.5`}
            >
                {!isMine && (
                    <div
                        className={`text-veryDarkGray dark:text-veryLightGray font-bold text-xs`}
                    >
                        {comment.author}
                    </div>
                )}
                <div
                    className={`text-veryDarkGray dark:text-veryLightGray font-normal text-sm pb-4`}
                >
                    {comment.message}
                </div>
                <div
                    className={`self-end text-veryDarkGray dark:text-veryLightGray font-bold text-xs`}
                >
                    {new Date(comment.timestamp).toLocaleString()}
                </div>
            </div>
        </div>
    )
}

export default CommentCard
