import Card from '../../components/atoms/Card'
import { useState } from 'react'
import CustomInputField from '../../components/atoms/CustomInputField'
import DynamicListManager from '../../components/molecules/DynamicListManager'
import { CustomButton } from '../../components/buttons/CustomButton'
import { ProjectId, ProjectOutput } from '../../api/openapi'
import { useNavigate } from 'react-router-dom'
import { useFetchOnDemand } from '../../hooks/useFetchOnDemand'
import {
    closeProject,
    createProject,
    updateProject,
} from '../../middleware/projectService'
import CustomCheckbox from '../../components/atoms/CustomCheckbox'
import { ApiFunction } from '../../types/apiFunction'

export const ProjectSettingsCard = ({
    project,
    onClose,
}: {
    project: ProjectOutput | null
    onClose: () => void
}) => {
    const [title, setTitle] = useState(project?.title || '')
    const [description, setDescription] = useState(project?.description || '')
    // const [institution, setInstitution] = useState(project?.owner || '')
    const [tags, setTags] = useState<string[]>(project?.tags || [])
    const [collaborators, setCollaborators] = useState<string[]>(
        project?.collaborators || []
    )

    const {
        fetchData: backendCall,
        loading,
        error,
    } = useFetchOnDemand(
        async (apiFun) => {
            if (apiFun === ApiFunction.UPDATE) {
                return updateProject({
                    id: project!.id,
                    title,
                    description,
                    tags,
                    collaborators,
                })
            } else if (apiFun === ApiFunction.CREATE) {
                return createProject({
                    title,
                    description,
                    tags,
                    collaborators,
                })
            } else {
                return closeProject(project!.id)
            }
        },
        (data) => {
            return data
        }
    )

    const navigate = useNavigate()

    const handleCreateProject = async () => {
        backendCall(ApiFunction.CREATE).then((result) => {
            navigate(
                '/wizard/' +
                    (result as ProjectId).projectId +
                    '/literature-search'
            )
        })
    }

    const handleUpdateProject = async () => {
        backendCall(ApiFunction.UPDATE).then(() => {})
    }

    const handleDeleteProject = async () => {
        backendCall(ApiFunction.DELETE).then(() => {
            navigate('/')
        })
    }

    const handleClose = () => {
        onClose()
    }

    const handleTagsChange = (updatedTags: string[]) => {
        setTags(updatedTags)
    }

    const handleCollaboratorsChange = (updatedCollaborators: string[]) => {
        setCollaborators(updatedCollaborators)
    }

    return (
        <Card>
            <div
                className={`flex flex-col gap-4 px-5 pt-4 pb-5 min-w-[600px] w-auto text-veryDarkGray`}
            >
                <div className={`text-2xl font-bold text-blue text-left`}>
                    Project Settings
                </div>

                <div className={`flex flex-col gap-4 pb-4`}>
                    <CustomInputField
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        label="Title"
                        placeholder={'Enter project title'}
                    />

                    <CustomInputField
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        label="Description"
                        placeholder={'Enter project description'}
                        multiline
                    />

                    {/*<CustomInputField*/}
                    {/*    value={institution}*/}
                    {/*    onChange={(e) => setInstitution(e.target.value)}*/}
                    {/*    label="Institution"*/}
                    {/*    placeholder={'Enter institution name'}*/}
                    {/*/>*/}

                    <DynamicListManager
                        initialItems={tags}
                        placeholder={'Enter tags'}
                        onListChange={handleTagsChange}
                        backgroundColor={'lightGray'}
                    />

                    <DynamicListManager
                        initialItems={collaborators}
                        placeholder={'Enter collaborators'}
                        onListChange={handleCollaboratorsChange}
                        backgroundColor={'lightGray'}
                    />
                    <CustomCheckbox label="Notify me via E-mail when a long running process finishes." />
                    {project && (
                        <label
                            onClick={handleDeleteProject}
                            className="font-medium text-red hover:underline cursor-pointer w-fit"
                        >
                            Close project
                        </label>
                    )}
                </div>
                {project && (
                    <div className={`flex flex-row gap-2 self-end`}>
                        <CustomButton
                            backgroundColor={'lightGray'}
                            textColor={'blue'}
                            text={'Close'}
                            onClick={handleClose}
                        />
                        <CustomButton
                            text={'Save'}
                            onClick={handleUpdateProject}
                            loading={loading}
                        />
                    </div>
                )}
                {!project && (
                    <div className={`flex flex-row gap-2 self-end`}>
                        <CustomButton
                            backgroundColor={'lightGray'}
                            textColor={'blue'}
                            text={'Cancel'}
                            onClick={handleClose}
                        />
                        <CustomButton
                            text={'Start'}
                            onClick={handleCreateProject}
                            loading={loading}
                        />
                    </div>
                )}
            </div>
        </Card>
    )
}
