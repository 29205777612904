import React, { createContext, useContext, useState, useEffect } from 'react'
import { ProjectOutput, UserHistoryV2 } from '../api/openapi'
import { useParams } from 'react-router-dom'
import { getHistory } from '../middleware/historyService'
import { fetchProjects } from '../middleware/projectService'

// Create the context
const WizardContext = createContext<{
    historyData: UserHistoryV2 | null
    projectData: ProjectOutput | null
    loading: boolean
    error: any
    isOwner: boolean
}>({
    historyData: null,
    projectData: null,
    loading: true,
    error: null,
    isOwner: false,
})

// Custom hook to use the WizardContext
export const useData = () => useContext(WizardContext)

// Provider component
export const WizardProvider = ({ children }: { children: React.ReactNode }) => {
    const { projectId } = useParams<{ projectId: string }>()
    const { searchId } = useParams<{ searchId: string }>()
    const { ideasSetId } = useParams<{ ideasSetId: string }>()
    const { ideaId } = useParams<{ ideaId: string }>()

    const [projectData, setProjectData] = useState<ProjectOutput | null>(null)
    const [historyData, setData] = useState<UserHistoryV2 | null>(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [isOwner, setIsOwner] = useState(false)

    // Fetch data from the API
    const fetchData = () => {
        fetchProjects().then((data) => {
            setIsOwner(
                data.ownerProjects?.find(
                    (project) => project.id === projectId
                ) !== undefined
            )
            setProjectData(
                data?.ownerProjects?.find(
                    (project) => project.id === projectId
                ) ||
                    data?.collaboratorProjects?.find(
                        (project) => project.id === projectId
                    )!
            )
            getHistory(projectId!).then((data) => {
                setData(data)
                setLoading(false)
            })
        })
    }

    // Fetch data on component mount
    useEffect(() => {
        fetchData()
    }, [projectId, searchId, ideasSetId, ideaId])

    return (
        <WizardContext.Provider
            value={{ projectData, historyData, loading, error, isOwner }}
        >
            {children}
        </WizardContext.Provider>
    )
}
