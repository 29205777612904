/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthorisationResponse
 */
export interface AuthorisationResponse {
    /**
     * 
     * @type {number}
     * @memberof AuthorisationResponse
     */
    statusCode: number;
    /**
     * Authorisation result
     * @type {boolean}
     * @memberof AuthorisationResponse
     */
    result: boolean;
    /**
     * User's JWT token
     * @type {string}
     * @memberof AuthorisationResponse
     */
    jwtToken: string;
    /**
     * Refresh token
     * @type {string}
     * @memberof AuthorisationResponse
     */
    refreshToken: string;
    /**
     * User's email
     * @type {string}
     * @memberof AuthorisationResponse
     */
    userEmail: string;
    /**
     * User's name
     * @type {string}
     * @memberof AuthorisationResponse
     */
    userName: string;
    /**
     * Whether user has a profile already created or not
     * @type {boolean}
     * @memberof AuthorisationResponse
     */
    hasProfile: boolean;
}

/**
 * Check if a given object implements the AuthorisationResponse interface.
 */
export function instanceOfAuthorisationResponse(value: object): value is AuthorisationResponse {
    if (!('statusCode' in value) || value['statusCode'] === undefined) return false;
    if (!('result' in value) || value['result'] === undefined) return false;
    if (!('jwtToken' in value) || value['jwtToken'] === undefined) return false;
    if (!('refreshToken' in value) || value['refreshToken'] === undefined) return false;
    if (!('userEmail' in value) || value['userEmail'] === undefined) return false;
    if (!('userName' in value) || value['userName'] === undefined) return false;
    if (!('hasProfile' in value) || value['hasProfile'] === undefined) return false;
    return true;
}

export function AuthorisationResponseFromJSON(json: any): AuthorisationResponse {
    return AuthorisationResponseFromJSONTyped(json, false);
}

export function AuthorisationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorisationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'statusCode': json['status_code'],
        'result': json['result'],
        'jwtToken': json['jwt_token'],
        'refreshToken': json['refresh_token'],
        'userEmail': json['user_email'],
        'userName': json['user_name'],
        'hasProfile': json['has_profile'],
    };
}

export function AuthorisationResponseToJSON(value?: AuthorisationResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status_code': value['statusCode'],
        'result': value['result'],
        'jwt_token': value['jwtToken'],
        'refresh_token': value['refreshToken'],
        'user_email': value['userEmail'],
        'user_name': value['userName'],
        'has_profile': value['hasProfile'],
    };
}

