import { useGoogleLogin } from '@react-oauth/google'
import Card from '../../components/atoms/Card'
import { Subtitle } from '../../components/atoms/Subtitle'
import LoginButton from '../../components/buttons/LoginButton'
import { FaGoogle, FaLinkedin } from 'react-icons/fa'
import { TfiMicrosoftAlt } from 'react-icons/tfi'
import { CustomButton } from '../../components/buttons/CustomButton'
import { Title } from '../../components/atoms/Title'
import { ReactComponent as SmallLogo } from '../../assets/small-logo.svg'
import {
    googleAuthorization,
    microsoftAuthorization,
} from '../../middleware/authService'
import { useLinkedIn } from 'react-linkedin-login-oauth2'
import { useEffect, useState } from 'react'
import { msalInstance } from '../../utils/authConfig'
import { useNavigate } from 'react-router-dom'
import LocalStorageUtil from '../../utils/LocalStorageUtil'

export function Login() {
    const [isMsalInitialized, setIsMsalInitialized] = useState(false)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    // Initialize MSAL on component mount
    useEffect(() => {
        msalInstance.initialize().then(() => setIsMsalInitialized(true))
    }, [])

    const googleLogin = useGoogleLogin({
        flow: 'auth-code', // Request an authorization code instead of an access token
        onSuccess: async (response) => {
            console.log('Login successful', response)
            setLoading(true)
            // Now send the authorization code (response.code) to your backend
            googleAuthorization(response.code).then(
                (data) => {
                    setLoading(false)
                    //save the token in local storage
                    LocalStorageUtil.setToken(data.jwtToken)
                    LocalStorageUtil.setRefreshToken(data.refreshToken)
                    const email = JSON.parse(
                        atob(data.jwtToken.split('.')[1])
                    ).user_email
                    LocalStorageUtil.setEmail(email)
                    navigate('/')
                },
                (error) => {
                    console.error('Login failed', error)
                    setLoading(false)
                }
            )
        },
        onError: () => {
            console.error('Login failed')
        },
    })

    const { linkedInLogin } = useLinkedIn({
        clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID || '',
        redirectUri: `http://localhost:3006/linkedin/auth`,
        onSuccess: (code) => {
            console.log(code)
        },
        onError: (error) => {
            console.log(error)
        },
    })

    const microsoftLogin = async () => {
        if (!isMsalInitialized) {
            console.error('MSAL is not initialized')
            return
        }

        try {
            // Trigger Microsoft sign-in with a popup
            const loginResponse = await msalInstance.loginPopup({
                scopes: ['User.Read'], // Define your desired scopes (e.g., User.Read for basic profile access)
            })

            // Retrieve the access token (or ID token if no permissions were requested)
            const accessToken = loginResponse.accessToken

            // Send the access token to your backend for further processing
            await microsoftAuthorization(accessToken)
            console.log('Microsoft login successful')
        } catch (error) {
            console.error('Microsoft login failed', error)
        }
    }

    return (
        <div className="flex items-center justify-center pt-10">
            <div className="w-[800px] h-full flex items-center justify-center">
                <Card>
                    {!loading && (
                        <div className="flex items-center flex-col">
                            {/* ↓ Title & subtitle section ↓  */}

                            <div className="flex flex-row justify-center items-center pb-6 pr-10">
                                <div className="flex flex-col items-center pb-5">
                                    <SmallLogo className="h-[100px] w-[100px] pb-6" />
                                </div>

                                <div className="flex flex-col items-center pb-5">
                                    <Title
                                        className="m-0 p-0"
                                        text={'Erudita Login'}
                                    />
                                    <Subtitle
                                        className="text-4xl text-darkgray dark:text-gray"
                                        text={'Sign in to your account'}
                                    />
                                </div>
                            </div>

                            {/* ↓ Flex container for both sections ↓  */}
                            <div className="flex justify-between items-stretch">
                                {/* ↓ Login with providers buttons ↓  */}
                                <div className="flex flex-col items-stretch p-5">
                                    <div className="flex items-center p-2">
                                        <LoginButton
                                            text={'Login with GOOGLE'}
                                            onClick={googleLogin}
                                            icon={
                                                <FaGoogle className="h-7 w-7 mr-2 text-blue" />
                                            }
                                        />
                                    </div>
                                    <div className="flex items-center p-2">
                                        <LoginButton
                                            text={'Login with MICROSOFT'}
                                            onClick={microsoftLogin}
                                            icon={
                                                <TfiMicrosoftAlt className="h-7 w-7 mr-2 text-blue" />
                                            }
                                        />
                                    </div>
                                    <div className="flex items-center p-2">
                                        <LoginButton
                                            text={'Login with LINKEDIN'}
                                            onClick={linkedInLogin}
                                            icon={
                                                <FaLinkedin className="h-7 w-7 mr-2 text-blue" />
                                            }
                                        />
                                    </div>
                                </div>
                                {/* ↓ Separator ↓  */}
                                <div className="border-l-2 border-yellow h-30 mx-5"></div>

                                {/* ↓ Contact us section ↓  */}
                                <div className="flex flex-col items-center justify-center p-14">
                                    <Subtitle
                                        text={"Don't have an account?"}
                                        className="font-bold pb-4 text-darkgray dark:text-gray"
                                    />
                                    <CustomButton
                                        text={'Contact us!'}
                                        onClick={() => {}}
                                        backgroundColor="blue"
                                        textColor="white"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {loading && (
                        <div className="flex items-center h-[100px] justify-center text-center text-veryDarkGray dark:text-veryLightGray">
                            <span>Loading...</span>
                        </div>
                    )}
                </Card>
            </div>
        </div>
    )
}
