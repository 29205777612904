/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Journal
 */
export interface Journal {
    /**
     * Name of the journal
     * @type {string}
     * @memberof Journal
     */
    journalName: string;
    /**
     * Journal Impact Factor
     * @type {string}
     * @memberof Journal
     */
    jIF2022: string;
}

/**
 * Check if a given object implements the Journal interface.
 */
export function instanceOfJournal(value: object): value is Journal {
    if (!('journalName' in value) || value['journalName'] === undefined) return false;
    if (!('jIF2022' in value) || value['jIF2022'] === undefined) return false;
    return true;
}

export function JournalFromJSON(json: any): Journal {
    return JournalFromJSONTyped(json, false);
}

export function JournalFromJSONTyped(json: any, ignoreDiscriminator: boolean): Journal {
    if (json == null) {
        return json;
    }
    return {
        
        'journalName': json['journal_name'],
        'jIF2022': json['JIF2022'],
    };
}

export function JournalToJSON(value?: Journal | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'journal_name': value['journalName'],
        'JIF2022': value['jIF2022'],
    };
}

