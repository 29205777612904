import type {
    FetchFulltextResponse,
    FileEnum,
    IntroTextResponse,
} from '../api/openapi'
import apiClient from '../api/apiClient'

export const generateProtocol = (
    projectId: string,
    selectedIdeaId: string,
    authors: string,
    institutions: string
): Promise<IntroTextResponse> => {
    return apiClient.getIntroductionTextV2GenerateIntroV2Post({
        introTextRequestV2: {
            projectId,
            selectedIdeaId,
            authors,
            institutions,
            timestamp: new Date().toISOString(),
        },
    })
}

export const getFullText = (
    fulltextId: string
): Promise<FetchFulltextResponse> => {
    return apiClient.fetchFulltextFetchFulltextPost({
        fetchFulltext: {
            fulltextId,
        },
    })
}

export const getFullTextFile = (
    fulltextId: string,
    format: FileEnum
): Promise<Blob> => {
    return apiClient.fetchFulltextFetchFulltextFilePost({
        fetchFulltextFile: {
            fulltextId,
            format,
        },
    })
}
