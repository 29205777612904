/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchQueryGenerationResponse
 */
export interface SearchQueryGenerationResponse {
    /**
     * 
     * @type {number}
     * @memberof SearchQueryGenerationResponse
     */
    statusCode: number;
    /**
     * PubMed search query generated
     * @type {string}
     * @memberof SearchQueryGenerationResponse
     */
    searchQuery: string;
    /**
     * List of keywords associated to the generated query
     * @type {Array<any>}
     * @memberof SearchQueryGenerationResponse
     */
    keywords: Array<any>;
}

/**
 * Check if a given object implements the SearchQueryGenerationResponse interface.
 */
export function instanceOfSearchQueryGenerationResponse(value: object): value is SearchQueryGenerationResponse {
    if (!('statusCode' in value) || value['statusCode'] === undefined) return false;
    if (!('searchQuery' in value) || value['searchQuery'] === undefined) return false;
    if (!('keywords' in value) || value['keywords'] === undefined) return false;
    return true;
}

export function SearchQueryGenerationResponseFromJSON(json: any): SearchQueryGenerationResponse {
    return SearchQueryGenerationResponseFromJSONTyped(json, false);
}

export function SearchQueryGenerationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchQueryGenerationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'statusCode': json['status_code'],
        'searchQuery': json['search_query'],
        'keywords': json['keywords'],
    };
}

export function SearchQueryGenerationResponseToJSON(value?: SearchQueryGenerationResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status_code': value['statusCode'],
        'search_query': value['searchQuery'],
        'keywords': value['keywords'],
    };
}

