/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FetchIndividualSearch
 */
export interface FetchIndividualSearch {
    /**
     * Project ID
     * @type {string}
     * @memberof FetchIndividualSearch
     */
    projectId: string;
    /**
     * Search ID
     * @type {string}
     * @memberof FetchIndividualSearch
     */
    searchId: string;
}

/**
 * Check if a given object implements the FetchIndividualSearch interface.
 */
export function instanceOfFetchIndividualSearch(value: object): value is FetchIndividualSearch {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('searchId' in value) || value['searchId'] === undefined) return false;
    return true;
}

export function FetchIndividualSearchFromJSON(json: any): FetchIndividualSearch {
    return FetchIndividualSearchFromJSONTyped(json, false);
}

export function FetchIndividualSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): FetchIndividualSearch {
    if (json == null) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'searchId': json['search_id'],
    };
}

export function FetchIndividualSearchToJSON(value?: FetchIndividualSearch | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'project_id': value['projectId'],
        'search_id': value['searchId'],
    };
}

