/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * Message content
     * @type {string}
     * @memberof Message
     */
    message: string;
    /**
     * Author
     * @type {string}
     * @memberof Message
     */
    author: string;
    /**
     * UTC timestamp
     * @type {number}
     * @memberof Message
     */
    timestamp: number;
}

/**
 * Check if a given object implements the Message interface.
 */
export function instanceOfMessage(value: object): value is Message {
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('author' in value) || value['author'] === undefined) return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    return true;
}

export function MessageFromJSON(json: any): Message {
    return MessageFromJSONTyped(json, false);
}

export function MessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Message {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'],
        'author': json['author'],
        'timestamp': json['timestamp'],
    };
}

export function MessageToJSON(value?: Message | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'message': value['message'],
        'author': value['author'],
        'timestamp': value['timestamp'],
    };
}

