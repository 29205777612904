import ArrowButton from '../buttons/ArrowButton'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useData } from '../../context/WizardContext'
import { useEffect, useState } from 'react'
import { UserHistoryItem } from '../../api/openapi'

export const WizardProgressBar = () => {
    const { projectId } = useParams<{ projectId: string }>()
    const { searchId } = useParams<{ searchId: string }>()
    const { ideasSetId } = useParams<{ ideasSetId: string }>()
    const { ideaId } = useParams<{ ideaId: string }>()
    const { historyData, loading, error } = useData()
    const [currentProject, setCurrentProject] =
        useState<UserHistoryItem | null>(null)

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        //find the history item of current project
        if (!historyData || !historyData.items) {
            return
        }
        setCurrentProject(
            historyData.items.find(
                (project) =>
                    project.searchId === searchId ||
                    project.ideasSetId === ideasSetId ||
                    project.ideaId === ideaId
            ) || null
        )
    }, [historyData])

    // Define the steps of the wizard as an array
    let steps = [
        {
            name: 'Literature Search',
            path: '/literature-search',
            disabled: false,
        },
        {
            name: 'Results extraction',
            path: '/results-extraction',
            disabled: !searchId && !currentProject?.searchId,
        },
        {
            name: 'Ideas generation',
            path: '/ideas-generation',
            disabled: !currentProject?.ideasSetId && !ideasSetId,
        },
        {
            name: 'Idea preview',
            path: '/idea-preview',
            disabled: !currentProject?.ideaId && !ideaId,
        },
        {
            name: 'Protocol generation',
            path: '/protocol-generation',
            disabled: !currentProject?.ideaId,
        },
    ]

    // Find the current step based on the current URL path
    const currentStepIndex = steps.findIndex((step) =>
        location.pathname.includes(step.path)
    )

    // Function to handle navigation to the previous step
    function onClickBack() {
        if (currentStepIndex > 0) {
            if (currentStepIndex === 1) {
                navigate(`/wizard/${projectId}/literature-search/${searchId}`)
            }
            if (currentStepIndex === 2) {
                if (searchId)
                    navigate(
                        `/wizard/${projectId}/results-extraction/${searchId}`
                    )
            }
            if (currentStepIndex === 3) {
                if (currentProject?.ideasSetId && currentProject?.ideaId)
                    navigate(
                        `/wizard/${projectId}/ideas-generation/${currentProject?.ideaId}/${currentProject?.ideasSetId}`
                    )
            }
            if (currentStepIndex === 4) {
                if (ideaId) {
                    navigate(`/wizard/${projectId}/idea-preview/${ideaId}`)
                }
            }
        }
    }

    // Function to handle navigation to the next step
    function onClickNext() {
        if (currentStepIndex < steps.length - 1) {
            if (currentStepIndex === 0) {
                if (searchId) {
                    navigate(
                        `/wizard/${projectId}/results-extraction/${searchId}`
                    )
                }
            }
            if (currentStepIndex === 1) {
                if (currentProject?.ideasSetId) {
                    navigate(
                        `/wizard/${projectId}/ideas-generation/${searchId}/${currentProject?.ideasSetId}`
                    )
                }
            }
            if (currentStepIndex === 2) {
                if (currentProject?.ideaId) {
                    navigate(
                        `/wizard/${projectId}/idea-preview/${currentProject?.ideaId}`
                    )
                }
            }
            if (currentStepIndex === 3) {
                if (ideaId && !currentProject?.protocolId)
                    navigate(
                        `/wizard/${projectId}/protocol-generation/${ideaId}`
                    )
                if (ideaId && currentProject?.protocolId)
                    navigate(
                        `/wizard/${projectId}/protocol-generation/${ideaId}/${currentProject?.protocolId}`
                    )
            }
        }
    }

    return (
        <div className={'flex flex-row gap-x-7 items-center'}>
            {/* Back button */}
            <div>
                <ArrowButton
                    onClick={onClickBack}
                    direction={'left'}
                    disabled={currentStepIndex === 0}
                    iconSize={4}
                />
            </div>

            {/* Progress steps */}
            <div className={'flex flex-row gap-x-7 items-center text-blue'}>
                {steps.map((step, index) => (
                    <div
                        key={step.path}
                        className={'flex flex-row items-center gap-x-4'}
                    >
                        <div
                            className={`text-sm ${
                                index === currentStepIndex
                                    ? 'font-bold text-blue underline' // Highlight current step
                                    : 'text-blue'
                            }
                            ${step.disabled && 'text-gray'}
                            `}
                        >
                            {step.name}
                        </div>

                        {/* Vertical line */}
                        {index < steps.length - 1 && (
                            <div className="flex items-center justify-center">
                                <div className="w-[2px] h-6 bg-yellow ml-3 mr-3" />
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {/* Next button */}
            <div>
                <ArrowButton
                    onClick={onClickNext}
                    direction={'right'}
                    disabled={currentStepIndex === steps.length - 1}
                    iconSize={4}
                />
            </div>
        </div>
    )
}
