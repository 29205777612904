import { FunctionButton } from '../buttons/FunctionButton'
import { HiOutlineHome } from 'react-icons/hi'
import { FiInfo } from 'react-icons/fi'
import { IoSettingsSharp } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { ProjectSettingsCard } from '../../features/project-settings/ProjectSettingsCard'
import { ProjectOutput } from '../../api/openapi'
import Modal from '../atoms/Modal'
import { useFetchOnDemand } from '../../hooks/useFetchOnDemand'
import { fetchProjects } from '../../middleware/projectService'
import { MdHistory } from 'react-icons/md'
import { getHistory } from '../../middleware/historyService'
import { HistoryTable } from '../../features/history-table/HistoryTable'
import { useData } from '../../context/WizardContext'

export const FunctionButtonsColumn = ({
    projectId,
    onShowHistoryClick,
}: {
    projectId: string
    onShowHistoryClick: () => void
}) => {
    const navigate = useNavigate()
    const [openProjectSettings, setOpenProjectSettings] =
        useState<boolean>(false)
    const [openHistory, setOpenHistory] = useState<boolean>(false)
    const { projectData, historyData, loading, error, isOwner } = useData()

    return (
        <div className="absolute -right-12 flex items-center justify-center">
            <div className="flex flex-col items-center gap-3">
                <FunctionButton
                    onClick={() => navigate('/')}
                    icon={<HiOutlineHome className="w-6 h-6" />}
                />
                <FunctionButton icon={<FiInfo className="w-6 h-6" />} />
                {isOwner && (
                    <FunctionButton
                        onClick={() => setOpenProjectSettings(true)}
                        icon={<IoSettingsSharp className="w-6 h-6" />}
                    />
                )}
                <FunctionButton
                    onClick={() => setOpenHistory(true)}
                    icon={<MdHistory className="w-6 h-6" />}
                />
            </div>

            <Modal
                isOpen={openProjectSettings}
                onClose={() => setOpenProjectSettings(false)}
            >
                {projectData && (
                    <ProjectSettingsCard
                        project={projectData}
                        onClose={() => setOpenProjectSettings(false)}
                    />
                )}
            </Modal>
            <Modal isOpen={openHistory} onClose={() => setOpenHistory(false)}>
                {historyData && historyData.items && (
                    <div className="flex flex-col items-center gap-4">
                        <HistoryTable
                            historyItems={historyData.items}
                            onClose={() => setOpenHistory(false)}
                        />
                    </div>
                )}
                {loading && (
                    <div className={'dark:text-veryLightGray'}>Loading...</div>
                )}
                {error && (
                    <div className={'dark:text-veryLightGray'}>Error...</div>
                )}
            </Modal>
        </div>
    )
}
