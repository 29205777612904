/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FetchIdeas
 */
export interface FetchIdeas {
    /**
     * Project ID
     * @type {string}
     * @memberof FetchIdeas
     */
    projectId: string;
    /**
     * ID of the ideas set
     * @type {string}
     * @memberof FetchIdeas
     */
    ideasSetId: string;
}

/**
 * Check if a given object implements the FetchIdeas interface.
 */
export function instanceOfFetchIdeas(value: object): value is FetchIdeas {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('ideasSetId' in value) || value['ideasSetId'] === undefined) return false;
    return true;
}

export function FetchIdeasFromJSON(json: any): FetchIdeas {
    return FetchIdeasFromJSONTyped(json, false);
}

export function FetchIdeasFromJSONTyped(json: any, ignoreDiscriminator: boolean): FetchIdeas {
    if (json == null) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'ideasSetId': json['ideas_set_id'],
    };
}

export function FetchIdeasToJSON(value?: FetchIdeas | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'project_id': value['projectId'],
        'ideas_set_id': value['ideasSetId'],
    };
}

