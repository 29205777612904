import React from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import type { ArticleInfoItemV2 } from '../../api/openapi'
import Card from '../../components/atoms/Card'
import { FunctionButton } from '../../components/buttons/FunctionButton'
import { ReactComponent as BackIcon } from '../../assets/back-icon.svg'
import { IconButton } from '../../components/buttons/IconButton'

export const ArticlesTable = ({
    articles,
    onClose,
}: {
    articles: ArticleInfoItemV2[]
    onClose: () => void
}) => {
    const columns: GridColDef[] = [
        {
            field: 'title',
            headerName: 'Title - Authors',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 2,
            renderCell: (params) => (
                <p className="text-sm font-bold text-veryDarkGray dark:text-veryLightGray">
                    {params.value}
                </p>
            ),
        },
        {
            field: 'population',
            headerName: 'Population',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 1,
            renderCell: (params) => (
                <p className="text-sm text-veryDarkGray dark:text-veryLightGray">
                    {params.value}
                </p>
            ),
        },
        {
            field: 'intervention',
            headerName: 'Intervention',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 1,
            renderCell: (params) => (
                <p className="text-sm text-veryDarkGray dark:text-veryLightGray">
                    {params.value}
                </p>
            ),
        },
        {
            field: 'outcome',
            headerName: 'Outcome',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 2,
            renderCell: (params) => (
                <p className="text-sm text-veryDarkGray dark:text-veryLightGray">
                    {params.value}
                </p>
            ),
        },
        {
            field: 'relevance',
            headerName: 'Relevance',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <p className="text-sm text-veryDarkGray dark:text-veryLightGray">
                    {params.value}
                </p>
            ),
        },
        {
            field: 'isReview',
            headerName: 'Type',
            headerClassName: 'text-blue bg-white dark:bg-bgBlack',
            flex: 0.5,
            valueGetter: (params) => (params === 'No' ? 'Article' : 'Review'),
            renderCell: (params) => (
                <p className="text-sm text-veryDarkGray dark:text-veryLightGray">
                    {params.value}
                </p>
            ),
        },
    ]

    return (
        <Card>
            <div className="flex flex-col w-full h-full text-veryDarkGray">
                <div className="flex mr-auto mb-4">
                    <IconButton onClick={onClose} icon={<BackIcon />} />
                </div>
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        hideFooter={true} // Removes the footer
                        rows={articles.map((article) => ({
                            ...article,
                            id: article.pmid, // MUI DataGrid requires a unique `id` field
                        }))}
                        columns={columns}
                        sx={{
                            boxShadow: 2,
                            border: 2,
                            borderColor: 'transparent',
                            backgroundColor: 'transparent',
                        }}
                    />
                </div>
            </div>
        </Card>
    )
}
