/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FetchIdeaSet
 */
export interface FetchIdeaSet {
    /**
     * Project ID
     * @type {string}
     * @memberof FetchIdeaSet
     */
    projectId: string;
    /**
     * ID of the idea associated to the Ideas Set
     * @type {string}
     * @memberof FetchIdeaSet
     */
    ideaId: string;
}

/**
 * Check if a given object implements the FetchIdeaSet interface.
 */
export function instanceOfFetchIdeaSet(value: object): value is FetchIdeaSet {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('ideaId' in value) || value['ideaId'] === undefined) return false;
    return true;
}

export function FetchIdeaSetFromJSON(json: any): FetchIdeaSet {
    return FetchIdeaSetFromJSONTyped(json, false);
}

export function FetchIdeaSetFromJSONTyped(json: any, ignoreDiscriminator: boolean): FetchIdeaSet {
    if (json == null) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'ideaId': json['idea_id'],
    };
}

export function FetchIdeaSetToJSON(value?: FetchIdeaSet | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'project_id': value['projectId'],
        'idea_id': value['ideaId'],
    };
}

