import { useState, useCallback } from 'react'

export const useFetchOnDemand = <T, R = T>(
    apiFunc: (arg?: any) => Promise<T>,
    transform?: (data: T) => R
) => {
    const [data, setData] = useState<R | null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)

    // Return a promise so it can be awaited
    const fetchData = useCallback(
        async (arg?: any) => {
            setLoading(true)
            setError(null)

            try {
                const result = await apiFunc(arg)
                const transformedData = transform
                    ? transform(result)
                    : (result as unknown as R)
                setData(transformedData)
                return transformedData // Resolve the promise with data
            } catch (err: any) {
                setError(err.message || 'Error fetching data')
            } finally {
                setLoading(false)
            }
        },
        [apiFunc, transform]
    )

    const clearData = () => {
        setData(null)
    }

    return { data, loading, error, fetchData, clearData }
}
