/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntroTextRequest
 */
export interface IntroTextRequest {
    /**
     * Project ID
     * @type {string}
     * @memberof IntroTextRequest
     */
    projectId: string;
    /**
     * ID of the idea selected
     * @type {string}
     * @memberof IntroTextRequest
     */
    selectedIdeaId: string;
    /**
     * Pubmed search query
     * @type {string}
     * @memberof IntroTextRequest
     */
    pubmedQuery: string;
    /**
     * Mininum year filter value for search associated
     * @type {number}
     * @memberof IntroTextRequest
     */
    minYear: number;
    /**
     * Maximum year filter value for search associated
     * @type {number}
     * @memberof IntroTextRequest
     */
    maxYear: number;
    /**
     * Authors (comma separated values)
     * @type {string}
     * @memberof IntroTextRequest
     */
    authors: string;
    /**
     * Institutions (comma separated values)
     * @type {string}
     * @memberof IntroTextRequest
     */
    institutions: string;
    /**
     * Timestamp when the endpoint call was made
     * @type {string}
     * @memberof IntroTextRequest
     */
    timestamp: string;
    /**
     * Whether the user wants to receive an email notification or not. Must be: 'True' or 'False'
     * @type {string}
     * @memberof IntroTextRequest
     */
    notificationEnabled: string;
}

/**
 * Check if a given object implements the IntroTextRequest interface.
 */
export function instanceOfIntroTextRequest(value: object): value is IntroTextRequest {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('selectedIdeaId' in value) || value['selectedIdeaId'] === undefined) return false;
    if (!('pubmedQuery' in value) || value['pubmedQuery'] === undefined) return false;
    if (!('minYear' in value) || value['minYear'] === undefined) return false;
    if (!('maxYear' in value) || value['maxYear'] === undefined) return false;
    if (!('authors' in value) || value['authors'] === undefined) return false;
    if (!('institutions' in value) || value['institutions'] === undefined) return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    if (!('notificationEnabled' in value) || value['notificationEnabled'] === undefined) return false;
    return true;
}

export function IntroTextRequestFromJSON(json: any): IntroTextRequest {
    return IntroTextRequestFromJSONTyped(json, false);
}

export function IntroTextRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntroTextRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'selectedIdeaId': json['selected_idea_id'],
        'pubmedQuery': json['pubmed_query'],
        'minYear': json['min_year'],
        'maxYear': json['max_year'],
        'authors': json['authors'],
        'institutions': json['institutions'],
        'timestamp': json['timestamp'],
        'notificationEnabled': json['notification_enabled'],
    };
}

export function IntroTextRequestToJSON(value?: IntroTextRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'project_id': value['projectId'],
        'selected_idea_id': value['selectedIdeaId'],
        'pubmed_query': value['pubmedQuery'],
        'min_year': value['minYear'],
        'max_year': value['maxYear'],
        'authors': value['authors'],
        'institutions': value['institutions'],
        'timestamp': value['timestamp'],
        'notification_enabled': value['notificationEnabled'],
    };
}

