/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FetchArticles
 */
export interface FetchArticles {
    /**
     * Project ID
     * @type {string}
     * @memberof FetchArticles
     */
    projectId: string;
    /**
     * List of article IDs
     * @type {Array<any>}
     * @memberof FetchArticles
     */
    articleIds: Array<any>;
    /**
     * List of review IDs
     * @type {Array<any>}
     * @memberof FetchArticles
     */
    reviewIds: Array<any>;
}

/**
 * Check if a given object implements the FetchArticles interface.
 */
export function instanceOfFetchArticles(value: object): value is FetchArticles {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('articleIds' in value) || value['articleIds'] === undefined) return false;
    if (!('reviewIds' in value) || value['reviewIds'] === undefined) return false;
    return true;
}

export function FetchArticlesFromJSON(json: any): FetchArticles {
    return FetchArticlesFromJSONTyped(json, false);
}

export function FetchArticlesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FetchArticles {
    if (json == null) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'articleIds': json['article_ids'],
        'reviewIds': json['review_ids'],
    };
}

export function FetchArticlesToJSON(value?: FetchArticles | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'project_id': value['projectId'],
        'article_ids': value['articleIds'],
        'review_ids': value['reviewIds'],
    };
}

