/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IdeaItem } from './IdeaItem';
import {
    IdeaItemFromJSON,
    IdeaItemFromJSONTyped,
    IdeaItemToJSON,
} from './IdeaItem';
import type { ArticleInfoItemV2 } from './ArticleInfoItemV2';
import {
    ArticleInfoItemV2FromJSON,
    ArticleInfoItemV2FromJSONTyped,
    ArticleInfoItemV2ToJSON,
} from './ArticleInfoItemV2';

/**
 * 
 * @export
 * @interface ListIdeaItems
 */
export interface ListIdeaItems {
    /**
     * List of ideas
     * @type {Array<IdeaItem>}
     * @memberof ListIdeaItems
     */
    results?: Array<IdeaItem>;
    /**
     * List of articles, and their information, that have been used for idea generation
     * @type {Array<ArticleInfoItemV2>}
     * @memberof ListIdeaItems
     */
    articles: Array<ArticleInfoItemV2>;
}

/**
 * Check if a given object implements the ListIdeaItems interface.
 */
export function instanceOfListIdeaItems(value: object): value is ListIdeaItems {
    if (!('articles' in value) || value['articles'] === undefined) return false;
    return true;
}

export function ListIdeaItemsFromJSON(json: any): ListIdeaItems {
    return ListIdeaItemsFromJSONTyped(json, false);
}

export function ListIdeaItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListIdeaItems {
    if (json == null) {
        return json;
    }
    return {
        
        'results': json['results'] == null ? undefined : ((json['results'] as Array<any>).map(IdeaItemFromJSON)),
        'articles': ((json['articles'] as Array<any>).map(ArticleInfoItemV2FromJSON)),
    };
}

export function ListIdeaItemsToJSON(value?: ListIdeaItems | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'results': value['results'] == null ? undefined : ((value['results'] as Array<any>).map(IdeaItemToJSON)),
        'articles': ((value['articles'] as Array<any>).map(ArticleInfoItemV2ToJSON)),
    };
}

