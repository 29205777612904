export const Subtitle = ({
    text,
    className,
}: {
    text: string
    className?: string
}) => {
    return (
        <h2
            className={`text-xl text-darkGray dark:text-veryLightGray ${className}`}
        >
            {text}
        </h2>
    )
}
