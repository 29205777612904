import apiClient from '../api/apiClient'
import {
    type BinaryResultsResponse,
    type ListMessages,
    StageEnum,
} from '../api/openapi'

export const getMessages = async (projectId: string): Promise<ListMessages> => {
    return apiClient.fetchMessagesApiFetchMessagesPost({
        projectId: { projectId: projectId },
    })
}

export const sendMessage = async (
    projectId: string,
    stage: StageEnum,
    message: string
): Promise<BinaryResultsResponse> => {
    // todo add author
    return apiClient.addMessageApiAddMessagePost({
        messageInput: {
            projectId: projectId,
            stage: stage,
            author: 'Anej@erudita.tech',
            message: message,
        },
    })
}
