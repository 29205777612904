/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuestionsRequestOld
 */
export interface QuestionsRequestOld {
    /**
     * Project ID
     * @type {string}
     * @memberof QuestionsRequestOld
     */
    projectId: string;
    /**
     * Raw search query
     * @type {string}
     * @memberof QuestionsRequestOld
     */
    searchTerms: string;
    /**
     * List of article IDs
     * @type {Array<any>}
     * @memberof QuestionsRequestOld
     */
    articleIds: Array<any>;
    /**
     * List of review IDs
     * @type {Array<any>}
     * @memberof QuestionsRequestOld
     */
    reviewIds: Array<any>;
    /**
     * Timestamp when the endpoint call was made
     * @type {string}
     * @memberof QuestionsRequestOld
     */
    timestamp: string;
    /**
     * ID associated to the search
     * @type {string}
     * @memberof QuestionsRequestOld
     */
    searchId: string;
    /**
     * Timestamp associated to the search query
     * @type {string}
     * @memberof QuestionsRequestOld
     */
    searchTs: string;
    /**
     * Whether the user wants to receive an email notification or not. Must be: 'True' or 'False'
     * @type {string}
     * @memberof QuestionsRequestOld
     */
    notificationEnabled: string;
}

/**
 * Check if a given object implements the QuestionsRequestOld interface.
 */
export function instanceOfQuestionsRequestOld(value: object): value is QuestionsRequestOld {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('searchTerms' in value) || value['searchTerms'] === undefined) return false;
    if (!('articleIds' in value) || value['articleIds'] === undefined) return false;
    if (!('reviewIds' in value) || value['reviewIds'] === undefined) return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    if (!('searchId' in value) || value['searchId'] === undefined) return false;
    if (!('searchTs' in value) || value['searchTs'] === undefined) return false;
    if (!('notificationEnabled' in value) || value['notificationEnabled'] === undefined) return false;
    return true;
}

export function QuestionsRequestOldFromJSON(json: any): QuestionsRequestOld {
    return QuestionsRequestOldFromJSONTyped(json, false);
}

export function QuestionsRequestOldFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionsRequestOld {
    if (json == null) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'searchTerms': json['searchTerms'],
        'articleIds': json['article_ids'],
        'reviewIds': json['review_ids'],
        'timestamp': json['timestamp'],
        'searchId': json['search_id'],
        'searchTs': json['search_ts'],
        'notificationEnabled': json['notification_enabled'],
    };
}

export function QuestionsRequestOldToJSON(value?: QuestionsRequestOld | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'project_id': value['projectId'],
        'searchTerms': value['searchTerms'],
        'article_ids': value['articleIds'],
        'review_ids': value['reviewIds'],
        'timestamp': value['timestamp'],
        'search_id': value['searchId'],
        'search_ts': value['searchTs'],
        'notification_enabled': value['notificationEnabled'],
    };
}

