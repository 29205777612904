import { FaMinus } from 'react-icons/fa'

export const MinusButton = ({
    onClick,
    iconSize = 6,
}: {
    onClick: () => void
    iconSize?: number
}) => {
    return (
        <button
            onClick={onClick}
            className={`flex items-center justify-center rounded-full bg-yellow text-white transition-transform transform hover:scale-110
                        w-${iconSize * 2} h-${iconSize * 2} p-1`}
        >
            <FaMinus className={`w-${iconSize} h-${iconSize}`} />
        </button>
    )
}
