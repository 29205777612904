import { ProjectsViewCard } from '../components/molecules/ProjectsViewCard'
import { useState } from 'react'
import { ArrowToggleButton } from '../components/atoms/ArrowToggleButton'

export function Home() {
    const [showHistory, setShowHistory] = useState(false)

    return (
        <div className="flex flex-col items-center justify-center h-full gap-4 px-2 md:px-4 xl:px-8">
            <ProjectsViewCard historyView={false} />

            <ArrowToggleButton
                label={'Show History'}
                value={showHistory}
                toggle={() => setShowHistory(!showHistory)}
            />

            {showHistory && (
                <div>
                    <ProjectsViewCard historyView={true} />
                </div>
            )}
        </div>
    )
}
