// src/authConfig.ts
import { PublicClientApplication, Configuration } from '@azure/msal-browser'

const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID || '',
        authority: 'https://login.microsoftonline.com/common',
        redirectUri:
            process.env.REACT_APP_REDIRECT_URI ||
            'http://localhost:3006/microsoft/auth',
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
    },
}

export const msalInstance = new PublicClientApplication(msalConfig)
