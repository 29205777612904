import { useState, useEffect } from 'react'

export const useFetch = <T, R = T>(
    apiFunc: () => Promise<T>,
    transform?: (data: T) => R,
    dependencies: any[] = []
) => {
    const [data, setData] = useState<R | null>(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        setLoading(true)
        setError(null)

        apiFunc()
            .then((result) => {
                const transformedData = transform
                    ? transform(result)
                    : (result as unknown as R)
                setData(transformedData)
                setLoading(false)
            })
            .catch((err) => {
                setError(err.message || 'Error fetching data')
                setLoading(false)
            })
    }, dependencies)

    return { data, loading, error }
}
