import { useEffect, useState } from 'react'
import LocalStorageUtil from '../utils/LocalStorageUtil'

const useDarkMode = () => {
    const [isDarkMode, setIsDarkMode] = useState(() => {
        return (
            LocalStorageUtil.getDarkMode() ||
            (!LocalStorageUtil.getDarkMode() &&
                window.matchMedia('(prefers-color-scheme: dark)').matches)
        )
    })

    useEffect(() => {
        if (isDarkMode) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }

        LocalStorageUtil.setDarkMode(isDarkMode)
    }, [isDarkMode])

    const toggleDarkMode = () => setIsDarkMode(!isDarkMode)

    return { isDarkMode, toggleDarkMode }
}

export default useDarkMode
