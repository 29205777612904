/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserProfileV2
 */
export interface UserProfileV2 {
    /**
     * User first name
     * @type {string}
     * @memberof UserProfileV2
     */
    firstName: string;
    /**
     * User last name
     * @type {string}
     * @memberof UserProfileV2
     */
    lastName: string;
    /**
     * Social media profile link
     * @type {string}
     * @memberof UserProfileV2
     */
    otherProfile: string;
    /**
     * Company
     * @type {string}
     * @memberof UserProfileV2
     */
    company: string;
    /**
     * User's job title
     * @type {string}
     * @memberof UserProfileV2
     */
    jobTitle: string;
    /**
     * User's job industry
     * @type {string}
     * @memberof UserProfileV2
     */
    industry: string;
    /**
     * Country
     * @type {string}
     * @memberof UserProfileV2
     */
    country: string;
    /**
     * Whether user wants to receive communications
     * @type {boolean}
     * @memberof UserProfileV2
     */
    communications: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileV2
     */
    notifications: boolean;
    /**
     * User's pubmed API key
     * @type {string}
     * @memberof UserProfileV2
     */
    pubmedApiKey?: string;
    /**
     * Timestamp when the endpoint call was made
     * @type {string}
     * @memberof UserProfileV2
     */
    timestamp: string;
}

/**
 * Check if a given object implements the UserProfileV2 interface.
 */
export function instanceOfUserProfileV2(value: object): value is UserProfileV2 {
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('otherProfile' in value) || value['otherProfile'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    if (!('jobTitle' in value) || value['jobTitle'] === undefined) return false;
    if (!('industry' in value) || value['industry'] === undefined) return false;
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('communications' in value) || value['communications'] === undefined) return false;
    if (!('notifications' in value) || value['notifications'] === undefined) return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    return true;
}

export function UserProfileV2FromJSON(json: any): UserProfileV2 {
    return UserProfileV2FromJSONTyped(json, false);
}

export function UserProfileV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileV2 {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'otherProfile': json['other_profile'],
        'company': json['company'],
        'jobTitle': json['job_title'],
        'industry': json['industry'],
        'country': json['country'],
        'communications': json['communications'],
        'notifications': json['notifications'],
        'pubmedApiKey': json['pubmed_api_key'] == null ? undefined : json['pubmed_api_key'],
        'timestamp': json['timestamp'],
    };
}

export function UserProfileV2ToJSON(value?: UserProfileV2 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'first_name': value['firstName'],
        'last_name': value['lastName'],
        'other_profile': value['otherProfile'],
        'company': value['company'],
        'job_title': value['jobTitle'],
        'industry': value['industry'],
        'country': value['country'],
        'communications': value['communications'],
        'notifications': value['notifications'],
        'pubmed_api_key': value['pubmedApiKey'],
        'timestamp': value['timestamp'],
    };
}

