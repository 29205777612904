/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PreviewSearch
 */
export interface PreviewSearch {
    /**
     * Pubmed search query
     * @type {string}
     * @memberof PreviewSearch
     */
    query: string;
    /**
     * Mininum year filter value for the search 
     * @type {number}
     * @memberof PreviewSearch
     */
    minYear: number;
    /**
     * Maximum year filter value for the search 
     * @type {number}
     * @memberof PreviewSearch
     */
    maxYear: number;
    /**
     * Timestamp when the endpoint call was made
     * @type {string}
     * @memberof PreviewSearch
     */
    timestamp: string;
}

/**
 * Check if a given object implements the PreviewSearch interface.
 */
export function instanceOfPreviewSearch(value: object): value is PreviewSearch {
    if (!('query' in value) || value['query'] === undefined) return false;
    if (!('minYear' in value) || value['minYear'] === undefined) return false;
    if (!('maxYear' in value) || value['maxYear'] === undefined) return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    return true;
}

export function PreviewSearchFromJSON(json: any): PreviewSearch {
    return PreviewSearchFromJSONTyped(json, false);
}

export function PreviewSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreviewSearch {
    if (json == null) {
        return json;
    }
    return {
        
        'query': json['query'],
        'minYear': json['min_year'],
        'maxYear': json['max_year'],
        'timestamp': json['timestamp'],
    };
}

export function PreviewSearchToJSON(value?: PreviewSearch | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'query': value['query'],
        'min_year': value['minYear'],
        'max_year': value['maxYear'],
        'timestamp': value['timestamp'],
    };
}

