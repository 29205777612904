/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchQueryPICO
 */
export interface SearchQueryPICO {
    /**
     * Population of PICO framework
     * @type {string}
     * @memberof SearchQueryPICO
     */
    population: string;
    /**
     * Intervention of PICO framework
     * @type {string}
     * @memberof SearchQueryPICO
     */
    intervention: string;
    /**
     * Outcome of PICO framework
     * @type {string}
     * @memberof SearchQueryPICO
     */
    outcome: string;
    /**
     * Others of PICO framework
     * @type {string}
     * @memberof SearchQueryPICO
     */
    others: string;
    /**
     * [Deprecated]
     * @type {number}
     * @memberof SearchQueryPICO
     */
    meshOption?: number;
    /**
     * [Deprecated]
     * @type {number}
     * @memberof SearchQueryPICO
     */
    wildcardsOption?: number;
    /**
     * Timestamp when the endpoint call was made
     * @type {string}
     * @memberof SearchQueryPICO
     */
    timestamp: string;
}

/**
 * Check if a given object implements the SearchQueryPICO interface.
 */
export function instanceOfSearchQueryPICO(value: object): value is SearchQueryPICO {
    if (!('population' in value) || value['population'] === undefined) return false;
    if (!('intervention' in value) || value['intervention'] === undefined) return false;
    if (!('outcome' in value) || value['outcome'] === undefined) return false;
    if (!('others' in value) || value['others'] === undefined) return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    return true;
}

export function SearchQueryPICOFromJSON(json: any): SearchQueryPICO {
    return SearchQueryPICOFromJSONTyped(json, false);
}

export function SearchQueryPICOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchQueryPICO {
    if (json == null) {
        return json;
    }
    return {
        
        'population': json['population'],
        'intervention': json['intervention'],
        'outcome': json['outcome'],
        'others': json['others'],
        'meshOption': json['mesh_option'] == null ? undefined : json['mesh_option'],
        'wildcardsOption': json['wildcards_option'] == null ? undefined : json['wildcards_option'],
        'timestamp': json['timestamp'],
    };
}

export function SearchQueryPICOToJSON(value?: SearchQueryPICO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'population': value['population'],
        'intervention': value['intervention'],
        'outcome': value['outcome'],
        'others': value['others'],
        'mesh_option': value['meshOption'],
        'wildcards_option': value['wildcardsOption'],
        'timestamp': value['timestamp'],
    };
}

