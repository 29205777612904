/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchTerm
 */
export interface SearchTerm {
    /**
     * Project ID
     * @type {string}
     * @memberof SearchTerm
     */
    projectId: string;
    /**
     * Raw search query - If pubmed query provided directly, this should be set to: Custom query: -keywords fetched from search_preview-
     * @type {string}
     * @memberof SearchTerm
     */
    searchQuery: string;
    /**
     * Pubmed search query
     * @type {string}
     * @memberof SearchTerm
     */
    pubmedQuery: string;
    /**
     * Mininum year filter value for the search
     * @type {number}
     * @memberof SearchTerm
     */
    minYear: number;
    /**
     * Maximum year filter value for the search
     * @type {number}
     * @memberof SearchTerm
     */
    maxYear: number;
    /**
     * List of keywords associated to the query
     * @type {Array<any>}
     * @memberof SearchTerm
     */
    keywords: Array<any>;
    /**
     * Timestamp when the endpoint call was made
     * @type {string}
     * @memberof SearchTerm
     */
    timestamp: string;
}

/**
 * Check if a given object implements the SearchTerm interface.
 */
export function instanceOfSearchTerm(value: object): value is SearchTerm {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('searchQuery' in value) || value['searchQuery'] === undefined) return false;
    if (!('pubmedQuery' in value) || value['pubmedQuery'] === undefined) return false;
    if (!('minYear' in value) || value['minYear'] === undefined) return false;
    if (!('maxYear' in value) || value['maxYear'] === undefined) return false;
    if (!('keywords' in value) || value['keywords'] === undefined) return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    return true;
}

export function SearchTermFromJSON(json: any): SearchTerm {
    return SearchTermFromJSONTyped(json, false);
}

export function SearchTermFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchTerm {
    if (json == null) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'searchQuery': json['search_query'],
        'pubmedQuery': json['pubmed_query'],
        'minYear': json['min_year'],
        'maxYear': json['max_year'],
        'keywords': json['keywords'],
        'timestamp': json['timestamp'],
    };
}

export function SearchTermToJSON(value?: SearchTerm | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'project_id': value['projectId'],
        'search_query': value['searchQuery'],
        'pubmed_query': value['pubmedQuery'],
        'min_year': value['minYear'],
        'max_year': value['maxYear'],
        'keywords': value['keywords'],
        'timestamp': value['timestamp'],
    };
}

