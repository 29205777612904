/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const StageEnum = {
    Search: 'search',
    Ideas: 'ideas',
    Protocol: 'protocol'
} as const;
export type StageEnum = typeof StageEnum[keyof typeof StageEnum];


export function instanceOfStageEnum(value: any): boolean {
    for (const key in StageEnum) {
        if (Object.prototype.hasOwnProperty.call(StageEnum, key)) {
            if (StageEnum[key as keyof typeof StageEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function StageEnumFromJSON(json: any): StageEnum {
    return StageEnumFromJSONTyped(json, false);
}

export function StageEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StageEnum {
    return json as StageEnum;
}

export function StageEnumToJSON(value?: StageEnum | null): any {
    return value as any;
}

