/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RefreshTokenResponse
 */
export interface RefreshTokenResponse {
    /**
     * User's JWT token
     * @type {string}
     * @memberof RefreshTokenResponse
     */
    jwtToken: string;
}

/**
 * Check if a given object implements the RefreshTokenResponse interface.
 */
export function instanceOfRefreshTokenResponse(value: object): value is RefreshTokenResponse {
    if (!('jwtToken' in value) || value['jwtToken'] === undefined) return false;
    return true;
}

export function RefreshTokenResponseFromJSON(json: any): RefreshTokenResponse {
    return RefreshTokenResponseFromJSONTyped(json, false);
}

export function RefreshTokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefreshTokenResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'jwtToken': json['jwt_token'],
    };
}

export function RefreshTokenResponseToJSON(value?: RefreshTokenResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'jwt_token': value['jwtToken'],
    };
}

