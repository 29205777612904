import React from 'react'
import './App.css'
import AppRouter from './router/AppRouter'
import Footer from './components/molecules/Footer'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const App = () => {
    return (
        <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
        >
            <div className="flex flex-col min-h-screen bg-lightGray dark:bg-darkBlue">
                <AppRouter />
                {/* Footer is fixed at the bottom */}
                <div className="fixed bottom-0 w-full">
                    <Footer />
                </div>
            </div>
            <ToastContainer />
        </GoogleOAuthProvider>
    )
}

export default App
