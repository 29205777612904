/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReviewItem
 */
export interface ReviewItem {
    /**
     * Pubmed ID
     * @type {string}
     * @memberof ReviewItem
     */
    pubmedID?: string;
    /**
     * Pubmed Central ID
     * @type {string}
     * @memberof ReviewItem
     */
    pMCID?: string;
    /**
     * DOI
     * @type {string}
     * @memberof ReviewItem
     */
    dOI?: string;
    /**
     * Publication date
     * @type {string}
     * @memberof ReviewItem
     */
    publicationDate?: string;
    /**
     * Publication year
     * @type {string}
     * @memberof ReviewItem
     */
    year?: string;
    /**
     * Authors
     * @type {string}
     * @memberof ReviewItem
     */
    authors?: string;
    /**
     * Citation
     * @type {string}
     * @memberof ReviewItem
     */
    citation?: string;
    /**
     * Short version of citation
     * @type {string}
     * @memberof ReviewItem
     */
    shortCitation?: string;
    /**
     * Journal name
     * @type {string}
     * @memberof ReviewItem
     */
    journalName?: string;
    /**
     * Journal ISSN
     * @type {string}
     * @memberof ReviewItem
     */
    journalISSN?: string;
    /**
     * Title
     * @type {string}
     * @memberof ReviewItem
     */
    title?: string;
    /**
     * Abstract
     * @type {string}
     * @memberof ReviewItem
     */
    _abstract?: string;
    /**
     * Whether there is fulltext available (1) or not (0)
     * @type {string}
     * @memberof ReviewItem
     */
    isFulltextAvailable?: string;
}

/**
 * Check if a given object implements the ReviewItem interface.
 */
export function instanceOfReviewItem(value: object): value is ReviewItem {
    return true;
}

export function ReviewItemFromJSON(json: any): ReviewItem {
    return ReviewItemFromJSONTyped(json, false);
}

export function ReviewItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewItem {
    if (json == null) {
        return json;
    }
    return {
        
        'pubmedID': json['Pubmed_ID'] == null ? undefined : json['Pubmed_ID'],
        'pMCID': json['PMC_ID'] == null ? undefined : json['PMC_ID'],
        'dOI': json['DOI'] == null ? undefined : json['DOI'],
        'publicationDate': json['Publication_date'] == null ? undefined : json['Publication_date'],
        'year': json['Year'] == null ? undefined : json['Year'],
        'authors': json['Authors'] == null ? undefined : json['Authors'],
        'citation': json['Citation'] == null ? undefined : json['Citation'],
        'shortCitation': json['Short_citation'] == null ? undefined : json['Short_citation'],
        'journalName': json['Journal_name'] == null ? undefined : json['Journal_name'],
        'journalISSN': json['Journal_ISSN'] == null ? undefined : json['Journal_ISSN'],
        'title': json['Title'] == null ? undefined : json['Title'],
        '_abstract': json['Abstract'] == null ? undefined : json['Abstract'],
        'isFulltextAvailable': json['Is_fulltext_available'] == null ? undefined : json['Is_fulltext_available'],
    };
}

export function ReviewItemToJSON(value?: ReviewItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Pubmed_ID': value['pubmedID'],
        'PMC_ID': value['pMCID'],
        'DOI': value['dOI'],
        'Publication_date': value['publicationDate'],
        'Year': value['year'],
        'Authors': value['authors'],
        'Citation': value['citation'],
        'Short_citation': value['shortCitation'],
        'Journal_name': value['journalName'],
        'Journal_ISSN': value['journalISSN'],
        'Title': value['title'],
        'Abstract': value['_abstract'],
        'Is_fulltext_available': value['isFulltextAvailable'],
    };
}

