import { Home } from '../pages/Home'
import { Login } from '../pages/login/Login'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom'
import { Wizard } from '../pages/wizard/Wizard'
import { LiteratureSearch } from '../pages/wizard/LiteratureSearch'
import { ResultsExtraction } from '../pages/wizard/ResultsExtraction'
import { IdeasGeneration } from '../pages/wizard/IdeasGeneration'
import { IdeaPreview } from '../pages/wizard/IdeaPreview'
import { ProtocolGeneration } from '../pages/wizard/ProtocolGeneration'
import React from 'react'
import Navbar from '../components/molecules/Navbar'

const AppRouter = () => (
    <Router>
        {/* Navbar is rendered on all pages */}
        <Navbar />
        <div className="flex-grow flex flex-col mt-[40px] pb-[80px]">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="wizard/:projectId" element={<Wizard />}>
                    <Route
                        index
                        element={<Navigate to="literature-search" />}
                    />
                    <Route
                        path="literature-search"
                        element={<LiteratureSearch />}
                    />
                    <Route
                        path="literature-search/:searchId"
                        element={<LiteratureSearch />}
                    />
                    <Route
                        path="results-extraction/:searchId"
                        element={<ResultsExtraction />}
                    />
                    <Route
                        path="ideas-generation/:searchId/:ideasSetId"
                        element={<IdeasGeneration />}
                    />
                    <Route
                        path="idea-preview/:ideaId"
                        element={<IdeaPreview />}
                    />
                    <Route
                        path="protocol-generation/:ideaId/:protocolId"
                        element={<ProtocolGeneration />}
                    />
                    <Route
                        path="protocol-generation/:ideaId"
                        element={<ProtocolGeneration />}
                    />
                </Route>
            </Routes>
        </div>
    </Router>
)

export default AppRouter
