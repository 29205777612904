import Card from '../atoms/Card'
import { Title } from '../atoms/Title'
import { ProjectCard } from './ProjectCard'
import ArrowButton from '../buttons/ArrowButton'
import { ListProjectsOutput, ProjectOutput } from '../../api/openapi'
import React, { useState } from 'react'
import Modal from '../atoms/Modal'
import { ProjectSettingsCard } from '../../features/project-settings/ProjectSettingsCard'
import { useFetch } from '../../hooks/useFetch'
import { useNavigate } from 'react-router-dom'
import { fetchProjects } from '../../middleware/projectService'
import { useFetchOnDemand } from '../../hooks/useFetchOnDemand'
import { IconButton } from '../buttons/IconButton'
import { FaPlus } from 'react-icons/fa'
import { getHistory } from '../../middleware/historyService'

export const ProjectsViewCard = ({
    historyView = false,
}: {
    historyView: boolean
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedProject, setSelectedProject] =
        useState<ProjectOutput | null>(null)
    const [currentIndex, setCurrentIndex] = useState(0) // State to track the current project index
    const projectsPerPage = 3 // Show 3 projects at a time

    const openModal = () => setIsModalOpen(true)
    const closeModal = () => setIsModalOpen(false)

    const navigate = useNavigate()

    // Fetch data using useFetchOnDemand hook
    const { data, loading, error } = useFetch<
        ListProjectsOutput,
        ProjectOutput[]
    >(
        () => fetchProjects(),
        (data) => {
            if (historyView)
                return (data.ownerProjects || []).filter(
                    (project) => project.status === 'CLOSED'
                )
            return (data.ownerProjects || []).filter(
                (project) => project.status === 'ACTIVE'
            )
        }
    )
    const { fetchData: getProjectHistoryCall, loading: loadingProjectHistory } =
        useFetchOnDemand((projectId: string) => getHistory(projectId))

    function onAddProject() {
        setSelectedProject(null)
        openModal()
    }

    function onPrevious() {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1)
        }
    }

    function onNext() {
        if (data && currentIndex < data.length - projectsPerPage) {
            setCurrentIndex(currentIndex + 1)
        }
    }

    function onProjectSettingsClick(index: number) {
        const projectIndex = currentIndex + index
        setSelectedProject(data![projectIndex] || null)
        setIsModalOpen(true)
    }

    function onProjectClick(project: ProjectOutput) {
        getProjectHistoryCall(project.id).then((data) => {
            if (!data) return

            const latestHistory = data.items?.sort((a, b) => {
                return a.timestamp.localeCompare(b.timestamp)
            })[0]

            if (!latestHistory) {
                navigate(`/wizard/${project.id}/literature-search`)
                return
            }
            if (latestHistory.ideaId && latestHistory.protocolId) {
                navigate(
                    `/wizard/${project.id}/protocol-generation/${latestHistory.ideaId}/${latestHistory.protocolId}`
                )
                return
            }
            if (latestHistory.ideaId) {
                navigate(
                    `/wizard/${project.id}/idea-preview/${latestHistory.ideaId}`
                )
                return
            }
            if (latestHistory.ideasSetId) {
                navigate(
                    `/wizard/${project.id}/ideas-generation/${latestHistory.searchId}/${latestHistory.ideasSetId}`
                )
                return
            }
            if (latestHistory.searchId) {
                navigate(
                    `/wizard/${project.id}/literature-search/${latestHistory.searchId}`
                )
                return
            }
            navigate(`/wizard/${project.id}/literature-search`)
        })
    }

    // Get the current set of displayed projects
    const displayedProjects = data
        ? data.slice(currentIndex, currentIndex + projectsPerPage)
        : []

    return (
        <div className={`flex flex-col gap-4 project-view-width`}>
            <Card>
                <div className={`flex flex-col gap-6 pb-2 px-2 items-center`}>
                    {!historyView && (
                        <div
                            className={`flex flex-row gap-2 items-center w-full`}
                        >
                            <div className={`flex flex-grow justify-end`}>
                                <Title text={'Project view'} />
                            </div>
                            <div
                                className={`flex flex-grow justify-end ml-auto`}
                            >
                                <IconButton
                                    onClick={onAddProject}
                                    icon={<FaPlus className={`w-6 h-6`} />}
                                />
                            </div>
                        </div>
                    )}

                    {/* Loading, Error, or Data Display */}
                    {loading && (
                        <div className="dark:text-veryLightGray">
                            Loading...
                        </div>
                    )}
                    {error && (
                        <div className="dark:text-veryLightGray">
                            Error: {error}
                        </div>
                    )}

                    {data && (
                        <div className={`flex flex-row gap-8 items-center`}>
                            <div
                                className={`${
                                    currentIndex > 0 ? 'block' : 'invisible'
                                }`}
                            >
                                <ArrowButton
                                    iconSize={6}
                                    direction={'left'}
                                    onClick={onPrevious}
                                />
                            </div>
                            <div className={`flex flex-row gap-4`}>
                                {displayedProjects.map((project, index) => (
                                    <ProjectCard
                                        key={project.id}
                                        project={project}
                                        onSettingsClick={() =>
                                            onProjectSettingsClick(index)
                                        }
                                        onProjectClick={() =>
                                            onProjectClick(project)
                                        }
                                    />
                                ))}
                            </div>
                            <div
                                className={`${
                                    currentIndex < data.length - projectsPerPage
                                        ? 'block'
                                        : 'invisible'
                                }`}
                            >
                                <ArrowButton
                                    iconSize={6}
                                    direction={'right'}
                                    onClick={onNext}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Card>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <ProjectSettingsCard
                    project={selectedProject}
                    onClose={closeModal}
                />
            </Modal>
        </div>
    )
}
