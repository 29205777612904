/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuestionsRequest
 */
export interface QuestionsRequest {
    /**
     * Project ID
     * @type {string}
     * @memberof QuestionsRequest
     */
    projectId: string;
    /**
     * ID associated to the search
     * @type {string}
     * @memberof QuestionsRequest
     */
    searchId: string;
    /**
     * Timestamp when the endpoint call was made
     * @type {string}
     * @memberof QuestionsRequest
     */
    timestamp: string;
}

/**
 * Check if a given object implements the QuestionsRequest interface.
 */
export function instanceOfQuestionsRequest(value: object): value is QuestionsRequest {
    if (!('projectId' in value) || value['projectId'] === undefined) return false;
    if (!('searchId' in value) || value['searchId'] === undefined) return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    return true;
}

export function QuestionsRequestFromJSON(json: any): QuestionsRequest {
    return QuestionsRequestFromJSONTyped(json, false);
}

export function QuestionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'projectId': json['project_id'],
        'searchId': json['search_id'],
        'timestamp': json['timestamp'],
    };
}

export function QuestionsRequestToJSON(value?: QuestionsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'project_id': value['projectId'],
        'search_id': value['searchId'],
        'timestamp': value['timestamp'],
    };
}

