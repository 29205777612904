/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SearchHistoryItemShort } from './SearchHistoryItemShort';
import {
    SearchHistoryItemShortFromJSON,
    SearchHistoryItemShortFromJSONTyped,
    SearchHistoryItemShortToJSON,
} from './SearchHistoryItemShort';

/**
 * 
 * @export
 * @interface ListSearchHistoryItems
 */
export interface ListSearchHistoryItems {
    /**
     * History of searches
     * @type {Array<SearchHistoryItemShort>}
     * @memberof ListSearchHistoryItems
     */
    results?: Array<SearchHistoryItemShort>;
}

/**
 * Check if a given object implements the ListSearchHistoryItems interface.
 */
export function instanceOfListSearchHistoryItems(value: object): value is ListSearchHistoryItems {
    return true;
}

export function ListSearchHistoryItemsFromJSON(json: any): ListSearchHistoryItems {
    return ListSearchHistoryItemsFromJSONTyped(json, false);
}

export function ListSearchHistoryItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListSearchHistoryItems {
    if (json == null) {
        return json;
    }
    return {
        
        'results': json['results'] == null ? undefined : ((json['results'] as Array<any>).map(SearchHistoryItemShortFromJSON)),
    };
}

export function ListSearchHistoryItemsToJSON(value?: ListSearchHistoryItems | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'results': value['results'] == null ? undefined : ((value['results'] as Array<any>).map(SearchHistoryItemShortToJSON)),
    };
}

