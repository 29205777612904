/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IdeaHistoryItem } from './IdeaHistoryItem';
import {
    IdeaHistoryItemFromJSON,
    IdeaHistoryItemFromJSONTyped,
    IdeaHistoryItemToJSON,
} from './IdeaHistoryItem';

/**
 * 
 * @export
 * @interface ListIdeaHistoryItems
 */
export interface ListIdeaHistoryItems {
    /**
     * History of ideas
     * @type {Array<IdeaHistoryItem>}
     * @memberof ListIdeaHistoryItems
     */
    results?: Array<IdeaHistoryItem>;
}

/**
 * Check if a given object implements the ListIdeaHistoryItems interface.
 */
export function instanceOfListIdeaHistoryItems(value: object): value is ListIdeaHistoryItems {
    return true;
}

export function ListIdeaHistoryItemsFromJSON(json: any): ListIdeaHistoryItems {
    return ListIdeaHistoryItemsFromJSONTyped(json, false);
}

export function ListIdeaHistoryItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListIdeaHistoryItems {
    if (json == null) {
        return json;
    }
    return {
        
        'results': json['results'] == null ? undefined : ((json['results'] as Array<any>).map(IdeaHistoryItemFromJSON)),
    };
}

export function ListIdeaHistoryItemsToJSON(value?: ListIdeaHistoryItems | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'results': value['results'] == null ? undefined : ((value['results'] as Array<any>).map(IdeaHistoryItemToJSON)),
    };
}

