import apiClient from '../api/apiClient'
import type { BinaryResultsResponse, UserProfileOutput } from '../api/openapi'

export const getUserProfile = (): Promise<UserProfileOutput> => {
    return apiClient.fetchProfileV2FetchProfileV2Post()
}

export const updateUserProfile = (
    firstName: string,
    lastName: string,
    otherProfile: string,
    company: string,
    jobTitle: string,
    industry: string,
    country: string,
    communications: boolean,
    notifications: boolean,
    pubmedApiKey: string
): Promise<BinaryResultsResponse> => {
    return apiClient.updateUserProfileUpdateProfileV2Post({
        userProfileV2: {
            firstName,
            lastName,
            otherProfile,
            company,
            jobTitle,
            industry,
            country,
            communications,
            notifications,
            pubmedApiKey,
            timestamp: new Date().toISOString(),
        },
    })
}
