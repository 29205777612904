/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectOutput } from './ProjectOutput';
import {
    ProjectOutputFromJSON,
    ProjectOutputFromJSONTyped,
    ProjectOutputToJSON,
} from './ProjectOutput';

/**
 * 
 * @export
 * @interface ListProjectsOutput
 */
export interface ListProjectsOutput {
    /**
     * List of project owned by the user
     * @type {Array<ProjectOutput>}
     * @memberof ListProjectsOutput
     */
    ownerProjects?: Array<ProjectOutput>;
    /**
     * List of projects where user is collaborator for
     * @type {Array<ProjectOutput>}
     * @memberof ListProjectsOutput
     */
    collaboratorProjects?: Array<ProjectOutput>;
}

/**
 * Check if a given object implements the ListProjectsOutput interface.
 */
export function instanceOfListProjectsOutput(value: object): value is ListProjectsOutput {
    return true;
}

export function ListProjectsOutputFromJSON(json: any): ListProjectsOutput {
    return ListProjectsOutputFromJSONTyped(json, false);
}

export function ListProjectsOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListProjectsOutput {
    if (json == null) {
        return json;
    }
    return {
        
        'ownerProjects': json['owner_projects'] == null ? undefined : ((json['owner_projects'] as Array<any>).map(ProjectOutputFromJSON)),
        'collaboratorProjects': json['collaborator_projects'] == null ? undefined : ((json['collaborator_projects'] as Array<any>).map(ProjectOutputFromJSON)),
    };
}

export function ListProjectsOutputToJSON(value?: ListProjectsOutput | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'owner_projects': value['ownerProjects'] == null ? undefined : ((value['ownerProjects'] as Array<any>).map(ProjectOutputToJSON)),
        'collaborator_projects': value['collaboratorProjects'] == null ? undefined : ((value['collaboratorProjects'] as Array<any>).map(ProjectOutputToJSON)),
    };
}

