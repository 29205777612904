/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FetchCtsInfo
 */
export interface FetchCtsInfo {
    /**
     * List of clinical trial IDs
     * @type {Array<any>}
     * @memberof FetchCtsInfo
     */
    ctIds: Array<any>;
}

/**
 * Check if a given object implements the FetchCtsInfo interface.
 */
export function instanceOfFetchCtsInfo(value: object): value is FetchCtsInfo {
    if (!('ctIds' in value) || value['ctIds'] === undefined) return false;
    return true;
}

export function FetchCtsInfoFromJSON(json: any): FetchCtsInfo {
    return FetchCtsInfoFromJSONTyped(json, false);
}

export function FetchCtsInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FetchCtsInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'ctIds': json['ct_ids'],
    };
}

export function FetchCtsInfoToJSON(value?: FetchCtsInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ct_ids': value['ctIds'],
    };
}

