import Card from '../atoms/Card'
import { Journal } from '../../api/openapi'

export const JournalsListCard = ({
    journals,
    onClick,
}: {
    journals: Journal[]
    onClick?: (id: number) => void
}) => {
    return (
        <Card backgroundColor={'lightGray'}>
            <div
                className={`flex flex-col gap-4 bg-lightGray dark:bg-darkBlue`}
            >
                {journals.map((journal: Journal, index) => (
                    <div
                        key={journal.journalName}
                        className={`flex flex-col gap-2 cursor-pointer`}
                    >
                        <div className={`flex justify-between items-baseline`}>
                            <div
                                className={`text-base text-blue font-bold text-left`}
                            >
                                {journal.journalName}
                            </div>

                            <span
                                className={`text-sm text-left text-veryDarkGray dark:text-veryLightGray font-bold`}
                            >
                                <span>Impact factor: </span>
                                {journal.jIF2022}
                            </span>
                        </div>
                        {/* Horizontal line */}
                        {index !== journals.length - 1 && (
                            <div className={`border-b border-yellow`} />
                        )}
                    </div>
                ))}
            </div>
        </Card>
    )
}
