/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchQueryAI
 */
export interface SearchQueryAI {
    /**
     * Raw search query
     * @type {string}
     * @memberof SearchQueryAI
     */
    query: string;
    /**
     * Timestamp when the endpoint call was made
     * @type {string}
     * @memberof SearchQueryAI
     */
    timestamp: string;
}

/**
 * Check if a given object implements the SearchQueryAI interface.
 */
export function instanceOfSearchQueryAI(value: object): value is SearchQueryAI {
    if (!('query' in value) || value['query'] === undefined) return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined) return false;
    return true;
}

export function SearchQueryAIFromJSON(json: any): SearchQueryAI {
    return SearchQueryAIFromJSONTyped(json, false);
}

export function SearchQueryAIFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchQueryAI {
    if (json == null) {
        return json;
    }
    return {
        
        'query': json['query'],
        'timestamp': json['timestamp'],
    };
}

export function SearchQueryAIToJSON(value?: SearchQueryAI | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'query': value['query'],
        'timestamp': value['timestamp'],
    };
}

