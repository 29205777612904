/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ArticleItem } from './ArticleItem';
import {
    ArticleItemFromJSON,
    ArticleItemFromJSONTyped,
    ArticleItemToJSON,
} from './ArticleItem';

/**
 * 
 * @export
 * @interface SearchHistoryItem
 */
export interface SearchHistoryItem {
    /**
     * Search ID
     * @type {string}
     * @memberof SearchHistoryItem
     */
    id?: string;
    /**
     * User ID
     * @type {string}
     * @memberof SearchHistoryItem
     */
    userId?: string;
    /**
     * Timestamp of the search
     * @type {string}
     * @memberof SearchHistoryItem
     */
    timestamp?: string;
    /**
     * Original user's query
     * @type {string}
     * @memberof SearchHistoryItem
     */
    originalQuery?: string;
    /**
     * Processed query - search string
     * @type {string}
     * @memberof SearchHistoryItem
     */
    processedQuery?: string;
    /**
     * Total number of results matching the search
     * @type {number}
     * @memberof SearchHistoryItem
     */
    totalItems?: number;
    /**
     * Min year filter associated to the search
     * @type {number}
     * @memberof SearchHistoryItem
     */
    minYear?: number;
    /**
     * Max year filter associated to the search
     * @type {number}
     * @memberof SearchHistoryItem
     */
    maxYear?: number;
    /**
     * List of keywords associated to the search
     * @type {Array<any>}
     * @memberof SearchHistoryItem
     */
    keywords?: Array<any>;
    /**
     * List of article associated to the search
     * @type {Array<ArticleItem>}
     * @memberof SearchHistoryItem
     */
    articlesRetrieved?: Array<ArticleItem>;
    /**
     * List of review associated to the search
     * @type {Array<ArticleItem>}
     * @memberof SearchHistoryItem
     */
    reviewsRetrieved?: Array<ArticleItem>;
    /**
     * Number of articles associated to the search
     * @type {number}
     * @memberof SearchHistoryItem
     */
    numArticlesRetrieved?: number;
    /**
     * Number of reviews associated to the search
     * @type {number}
     * @memberof SearchHistoryItem
     */
    numReviewsRetrieved?: number;
}

/**
 * Check if a given object implements the SearchHistoryItem interface.
 */
export function instanceOfSearchHistoryItem(value: object): value is SearchHistoryItem {
    return true;
}

export function SearchHistoryItemFromJSON(json: any): SearchHistoryItem {
    return SearchHistoryItemFromJSONTyped(json, false);
}

export function SearchHistoryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchHistoryItem {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'userId': json['user_id'] == null ? undefined : json['user_id'],
        'timestamp': json['timestamp'] == null ? undefined : json['timestamp'],
        'originalQuery': json['original_query'] == null ? undefined : json['original_query'],
        'processedQuery': json['processed_query'] == null ? undefined : json['processed_query'],
        'totalItems': json['total_items'] == null ? undefined : json['total_items'],
        'minYear': json['min_year'] == null ? undefined : json['min_year'],
        'maxYear': json['max_year'] == null ? undefined : json['max_year'],
        'keywords': json['keywords'] == null ? undefined : json['keywords'],
        'articlesRetrieved': json['articles_retrieved'] == null ? undefined : ((json['articles_retrieved'] as Array<any>).map(ArticleItemFromJSON)),
        'reviewsRetrieved': json['reviews_retrieved'] == null ? undefined : ((json['reviews_retrieved'] as Array<any>).map(ArticleItemFromJSON)),
        'numArticlesRetrieved': json['num_articles_retrieved'] == null ? undefined : json['num_articles_retrieved'],
        'numReviewsRetrieved': json['num_reviews_retrieved'] == null ? undefined : json['num_reviews_retrieved'],
    };
}

export function SearchHistoryItemToJSON(value?: SearchHistoryItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'user_id': value['userId'],
        'timestamp': value['timestamp'],
        'original_query': value['originalQuery'],
        'processed_query': value['processedQuery'],
        'total_items': value['totalItems'],
        'min_year': value['minYear'],
        'max_year': value['maxYear'],
        'keywords': value['keywords'],
        'articles_retrieved': value['articlesRetrieved'] == null ? undefined : ((value['articlesRetrieved'] as Array<any>).map(ArticleItemToJSON)),
        'reviews_retrieved': value['reviewsRetrieved'] == null ? undefined : ((value['reviewsRetrieved'] as Array<any>).map(ArticleItemToJSON)),
        'num_articles_retrieved': value['numArticlesRetrieved'],
        'num_reviews_retrieved': value['numReviewsRetrieved'],
    };
}

