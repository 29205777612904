import Card from '../../components/atoms/Card'
import { IdeaCard } from '../../components/molecules/IdeaCard'

import React, { useState } from 'react'
import { CustomButton } from '../../components/buttons/CustomButton'
import { useNavigate, useParams } from 'react-router-dom'
import { IdeaHistoryItem, IdeaItem, StageEnum } from '../../api/openapi'
import { useFetch } from '../../hooks/useFetch'
import { fetchIdeas, generateIdeas } from '../../middleware/ideasService'
import { IdeasHistorySection } from '../../features/history-section/ideas-generation/IdeasHistorySection'
import CommentSection from '../../features/comment-section/CommentSection'
import { FunctionButtonsColumn } from '../../components/molecules/FunctionButtonsColumn'
import { useFetchOnDemand } from '../../hooks/useFetchOnDemand'
import { FiRefreshCcw } from 'react-icons/fi'
import { IoEye } from 'react-icons/io5'
import { ArticlesTable } from '../../features/articles-table/ArticlesTable'
import { TableViewButton } from '../../components/buttons/TableViewButton'
import { useData } from '../../context/WizardContext'

export const IdeasGeneration = () => {
    const { projectId } = useParams<{ projectId: string }>()
    const { searchId } = useParams<{ searchId: string }>()
    const { ideasSetId } = useParams<{ ideasSetId: string }>()
    const { isOwner } = useData()
    const navigate = useNavigate()

    const [selectedIdea, setSelectedIdea] = useState<IdeaItem>()
    const [articlesView, setArticlesView] = useState<boolean>(false)

    const { data, error, loading } = useFetch(
        () => fetchIdeas(projectId!, ideasSetId!),
        undefined,
        [projectId, ideasSetId]
    )
    const { fetchData: generateIdeasCall, loading: loadingGenerateIdeas } =
        useFetchOnDemand(() => {
            return generateIdeas(projectId!, searchId!)
        })

    const handleIdeaClick = (idea: IdeaItem) => {
        setSelectedIdea(idea)
    }

    const handleNext = () => {
        navigate(`/wizard/${projectId}/idea-preview/${selectedIdea?.id}`)
    }

    const handleSelectIdea = (idea: IdeaHistoryItem) => {
        //switch the ideasSetId to the selected idea set
        navigate(`/wizard/${projectId}/ideas-generation/${searchId}/${idea.id}`)
    }

    const regenerateIdeas = () => {
        generateIdeasCall().then((data) => {
            //navigate to the first idea in the list
            navigate(
                `/wizard/${projectId}/idea-generation/${searchId}/${data!.ideasSetId}`
            )
        })
    }

    const handleArticlesView = () => {
        setArticlesView(!articlesView)
    }

    return (
        <div className={'flex flex-col items-center gap-8'}>
            {loading && (
                <div className={'dark:text-veryLightGray'}>Loading...</div>
            )}
            {error && (
                <div className={'dark:text-veryLightGray'}>
                    Error loading ideas.
                </div>
            )}
            {!error && !loading && (
                <div className={'flex flex-col items-center gap-8 w-full'}>
                    <div
                        className={`flex flex-row items-start gap-8 ${articlesView ? 'w-full lg:max-w-[1000px] xl:max-w-[1100px] 2xl:w-[1500px]' : 'wizard-width'} relative`}
                    >
                        {!articlesView && (
                            <Card>
                                <div className="flex flex-col border border-blue bg-lightGray dark:bg-darkBlue items-center gap-4 p-4 rounded-md">
                                    <div className="flex flex-row items-center w-full">
                                        <div className="flex-1">
                                            <TableViewButton
                                                backgroundColorClass={
                                                    'bg-white dark:bg-bgBlack'
                                                }
                                                text={'Relevant articles'}
                                                onClick={handleArticlesView}
                                                icon={
                                                    <IoEye
                                                        className={`text-blue w-6 h-6`}
                                                    />
                                                }
                                            />
                                        </div>
                                        <div className="flex-1 flex justify-center">
                                            {isOwner && (
                                                <CustomButton
                                                    text={'Regenerate'}
                                                    onClick={regenerateIdeas}
                                                    icon={
                                                        <FiRefreshCcw
                                                            className={`text-blue w-6 h-6`}
                                                        />
                                                    }
                                                    loading={
                                                        loadingGenerateIdeas
                                                    }
                                                />
                                            )}
                                        </div>
                                        <div className="flex-1"></div>
                                        {/* Empty div to center "Regenerate" */}
                                    </div>
                                    {data?.results?.map((idea, index) => (
                                        <IdeaCard
                                            key={index}
                                            idea={idea}
                                            selected={selectedIdea?.id || ''}
                                            onClick={handleIdeaClick}
                                        />
                                    ))}
                                </div>
                            </Card>
                        )}
                        {articlesView && (
                            <ArticlesTable
                                articles={data?.articles || []}
                                onClose={() => setArticlesView(false)}
                            />
                        )}
                        <FunctionButtonsColumn
                            projectId={projectId!}
                            onShowHistoryClick={() => {}}
                        />
                    </div>
                    <div className={`flex gap-4 self-end`}>
                        {isOwner && (
                            <CustomButton
                                isDisabled={!selectedIdea}
                                text={'Next'}
                                onClick={handleNext}
                            />
                        )}
                    </div>
                </div>
            )}
            <CommentSection projectId={projectId!} stage={StageEnum.Ideas} />
            {/*<IdeasHistorySection*/}
            {/*    projectId={projectId!}*/}
            {/*    selectIdeaSet={handleSelectIdea}*/}
            {/*/>*/}
        </div>
    )
}
