/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListResultsResponse
 */
export interface ListResultsResponse {
    /**
     * 
     * @type {number}
     * @memberof ListResultsResponse
     */
    statusCode: number;
    /**
     * List of results
     * @type {Array<any>}
     * @memberof ListResultsResponse
     */
    results: Array<any>;
}

/**
 * Check if a given object implements the ListResultsResponse interface.
 */
export function instanceOfListResultsResponse(value: object): value is ListResultsResponse {
    if (!('statusCode' in value) || value['statusCode'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    return true;
}

export function ListResultsResponseFromJSON(json: any): ListResultsResponse {
    return ListResultsResponseFromJSONTyped(json, false);
}

export function ListResultsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListResultsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'statusCode': json['status_code'],
        'results': json['results'],
    };
}

export function ListResultsResponseToJSON(value?: ListResultsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status_code': value['statusCode'],
        'results': value['results'],
    };
}

