/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PreviewSearchResponse
 */
export interface PreviewSearchResponse {
    /**
     * 
     * @type {number}
     * @memberof PreviewSearchResponse
     */
    statusCode: number;
    /**
     * Number of articles found
     * @type {number}
     * @memberof PreviewSearchResponse
     */
    numArticles: number;
    /**
     * Number of reviews found
     * @type {number}
     * @memberof PreviewSearchResponse
     */
    numReviews: number;
    /**
     * Query executed
     * @type {string}
     * @memberof PreviewSearchResponse
     */
    query: string;
    /**
     * List of keyworkds associated to the query executed
     * @type {Array<any>}
     * @memberof PreviewSearchResponse
     */
    keywords: Array<any>;
}

/**
 * Check if a given object implements the PreviewSearchResponse interface.
 */
export function instanceOfPreviewSearchResponse(value: object): value is PreviewSearchResponse {
    if (!('statusCode' in value) || value['statusCode'] === undefined) return false;
    if (!('numArticles' in value) || value['numArticles'] === undefined) return false;
    if (!('numReviews' in value) || value['numReviews'] === undefined) return false;
    if (!('query' in value) || value['query'] === undefined) return false;
    if (!('keywords' in value) || value['keywords'] === undefined) return false;
    return true;
}

export function PreviewSearchResponseFromJSON(json: any): PreviewSearchResponse {
    return PreviewSearchResponseFromJSONTyped(json, false);
}

export function PreviewSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreviewSearchResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'statusCode': json['status_code'],
        'numArticles': json['num_articles'],
        'numReviews': json['num_reviews'],
        'query': json['query'],
        'keywords': json['keywords'],
    };
}

export function PreviewSearchResponseToJSON(value?: PreviewSearchResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status_code': value['statusCode'],
        'num_articles': value['numArticles'],
        'num_reviews': value['numReviews'],
        'query': value['query'],
        'keywords': value['keywords'],
    };
}

