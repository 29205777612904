// Middleware for creating a project
import apiClient from '../api/apiClient'
import type {
    BinaryResultsResponse,
    ListProjectsOutput,
    ProjectId,
} from '../api/openapi'

// Middleware for fetching all projects
export const fetchProjects = (): Promise<ListProjectsOutput> => {
    return apiClient.fetchProjectsApiV2FetchProjectsV2Post()
}

// Middleware for creating a project
export const createProject = ({
    title,
    description,
    tags,
    collaborators,
}: {
    title: string
    description: string
    tags: string[]
    collaborators: string[]
}): Promise<ProjectId> => {
    return apiClient.createProjectApiCreateProjectPost({
        project: {
            title,
            description,
            tags,
            collaborators,
        },
    })
}

// Middleware for updating a project
export const updateProject = ({
    id,
    title,
    description,
    tags,
    collaborators,
}: {
    id: string
    title: string
    description: string
    tags: string[]
    collaborators: string[]
}): Promise<BinaryResultsResponse> => {
    return apiClient.updateProjectApiUpdateProjectPost({
        projectUpdate: {
            id,
            title,
            description,
            tags,
            collaborators,
        },
    })
}

export const closeProject = (
    projectId: string
): Promise<BinaryResultsResponse> => {
    return apiClient.closeProjectApiCloseProjectPost({
        projectId: { projectId },
    })
}
