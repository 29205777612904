/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * Project title
     * @type {string}
     * @memberof Project
     */
    title: string;
    /**
     * Project description
     * @type {string}
     * @memberof Project
     */
    description: string;
    /**
     * List of tags associated to the project
     * @type {Array<any>}
     * @memberof Project
     */
    tags: Array<any>;
    /**
     * List of emails associated to the collaborators
     * @type {Array<any>}
     * @memberof Project
     */
    collaborators: Array<any>;
}

/**
 * Check if a given object implements the Project interface.
 */
export function instanceOfProject(value: object): value is Project {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('tags' in value) || value['tags'] === undefined) return false;
    if (!('collaborators' in value) || value['collaborators'] === undefined) return false;
    return true;
}

export function ProjectFromJSON(json: any): Project {
    return ProjectFromJSONTyped(json, false);
}

export function ProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): Project {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'description': json['description'],
        'tags': json['tags'],
        'collaborators': json['collaborators'],
    };
}

export function ProjectToJSON(value?: Project | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'description': value['description'],
        'tags': value['tags'],
        'collaborators': value['collaborators'],
    };
}

