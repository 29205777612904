import ToggleButtonGroup from '../../components/atoms/ToggleButtonGroup'
import { ArticleCard } from '../../components/molecules/ArticleCard'
import { ArticlesListCard } from '../../components/molecules/ArticlesListCard'
import { CustomButton } from '../../components/buttons/CustomButton'
import { useFetch } from '../../hooks/useFetch'
import {
    addArticleToProject,
    getArticlesAndReviews,
} from '../../middleware/searchService'
import React, { useEffect, useState } from 'react'
import { ArticleItem, StageEnum } from '../../api/openapi'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowToggleButton } from '../../components/atoms/ArrowToggleButton'
import CustomInputField from '../../components/atoms/CustomInputField'
import Card from '../../components/atoms/Card'
import { generateIdeas } from '../../middleware/ideasService'
import CommentSection from '../../features/comment-section/CommentSection'
import { FunctionButtonsColumn } from '../../components/molecules/FunctionButtonsColumn'
import { useFetchOnDemand } from '../../hooks/useFetchOnDemand'
import { useData } from '../../context/WizardContext'

export const ResultsExtraction = () => {
    const { projectId } = useParams<{ projectId: string }>()
    const { searchId } = useParams<{ searchId: string }>()
    const { isOwner } = useData()
    const navigate = useNavigate()

    const [selected, setSelected] = useState<string>('Articles')
    const [selectedArticle, setSelectedArticle] = useState<ArticleItem | null>(
        null
    )
    const [addArticle, setAddArticle] = useState(false)
    const [addedArticles, setAddedArticles] = useState<string[]>([])

    const {
        fetchData: getArticlesAndReviewsCall,
        data,
        loading: loadingGetArticles,
        error: errorGetArticles,
        clearData,
    } = useFetchOnDemand(() => {
        return getArticlesAndReviews(projectId!, searchId!)
    })
    const { fetchData: addArticleCall, loading: loadingAddArticles } =
        useFetchOnDemand(() => {
            return addArticleToProject(projectId!, searchId!, addedArticles)
        })
    const { fetchData: generateIdeasCall, loading: loadingGenerateIdeas } =
        useFetchOnDemand(() => {
            return generateIdeas(projectId!, searchId!)
        })

    useEffect(() => {
        clearData()
        getArticlesAndReviewsCall()
    }, [searchId])

    const handleSearchToggle = (selected: string) => {
        setSelected(selected)
    }

    const handleArticleClick = (doi: string) => {
        if (selected.includes('Articles')) {
            setSelectedArticle(
                data?.articlesRetrieved!.find(
                    (article) => article.dOI === doi
                ) || null
            )
        } else {
            setSelectedArticle(
                data?.reviewsRetrieved!.find(
                    (article) => article.dOI === doi
                ) || null
            )
        }
    }

    const handleAddArticle = () => {
        addArticleCall().then(() => {
            setAddArticle(false)
            setAddedArticles([])
        })
    }

    const handleNext = () => {
        generateIdeasCall().then((response) => {
            // navigate to next page
            if (response && response.ideasSetId)
                navigate(
                    '/wizard/' +
                        projectId +
                        '/ideas-generation/' +
                        searchId +
                        '/' +
                        response!.ideasSetId
                )
        })
    }

    return (
        <div className="flex flex-col items-stretch gap-3 w-full px-8 wizard-width">
            {!loadingGetArticles &&
                !errorGetArticles &&
                data?.articlesRetrieved &&
                data?.reviewsRetrieved && (
                    <div className={'flex flex-row items-start gap-8 relative'}>
                        <div className="grid grid-cols-2 gap-4">
                            <div className={`flex flex-col gap-4`}>
                                <ToggleButtonGroup
                                    options={[
                                        `${data?.articlesRetrieved.length} Articles`,
                                        `${data?.reviewsRetrieved.length} Reviews`,
                                    ]}
                                    onToggle={handleSearchToggle}
                                />
                                {selected.includes('Articles') ? (
                                    <ArticlesListCard
                                        backgroundColor={'white'}
                                        onClick={(doi) => {
                                            handleArticleClick(doi)
                                        }}
                                        articles={data.articlesRetrieved}
                                        disableSelect={false}
                                    />
                                ) : (
                                    <ArticlesListCard
                                        backgroundColor={'white'}
                                        onClick={(doi) => {
                                            handleArticleClick(doi)
                                        }}
                                        articles={data.reviewsRetrieved}
                                        disableSelect={false}
                                    />
                                )}
                            </div>
                            {selectedArticle && (
                                <div className={`flex flex-col gap-4`}>
                                    <ArticleCard
                                        keywords={data.keywords || []}
                                        article={selectedArticle}
                                    />
                                </div>
                            )}
                        </div>
                        <FunctionButtonsColumn
                            projectId={projectId!}
                            onShowHistoryClick={() => {}}
                        />
                    </div>
                )}
            {errorGetArticles && (
                <div className="flex gap-4 justify-center">
                    {errorGetArticles}
                </div>
            )}
            {loadingGetArticles && (
                <div className="flex gap-4 justify-center dark:text-veryLightGray">
                    Loading...
                </div>
            )}
            {!loadingGetArticles && !errorGetArticles && (
                <div className={`flex flex-col justify-end gap-4`}>
                    <ArrowToggleButton
                        value={addArticle}
                        toggle={() => setAddArticle(!addArticle)}
                        label={'Add article'}
                    />
                    {addArticle && (
                        <Card>
                            <div className={`flex flex-col gap-4`}>
                                <div
                                    className={`text-sm text-darkGray font-normal`}
                                >
                                    If you want to add more articles or reviews
                                    to the pool of results, introduce the
                                    corresponding DOI and clickAdd article. If
                                    you want to add more than one at a time,
                                    introduce the DOIs separated by commas.
                                </div>
                                <CustomInputField
                                    value={addedArticles.join(',')}
                                    placeholder={
                                        'I.E. : 10.3390/healthcare11182519, 10.11777/11.5873564387635, 10.4762098556'
                                    }
                                    onChange={(e) =>
                                        setAddedArticles(
                                            e.target.value.split(',')
                                        )
                                    }
                                />
                                <div className={`flex gap-4 justify-center`}>
                                    <CustomButton
                                        text={'Add article'}
                                        onClick={() => handleAddArticle()}
                                        loading={loadingAddArticles}
                                    />
                                </div>
                            </div>
                        </Card>
                    )}
                </div>
            )}
            {!loadingGetArticles && !errorGetArticles && isOwner && (
                <div className="flex gap-4 justify-end">
                    <CustomButton
                        text={'Generate ideas!'}
                        onClick={handleNext}
                        loading={loadingGenerateIdeas}
                    />
                </div>
            )}
            <CommentSection projectId={projectId!} stage={StageEnum.Ideas} />
        </div>
    )
}
