/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdeaHistoryItem
 */
export interface IdeaHistoryItem {
    /**
     * Search ID
     * @type {string}
     * @memberof IdeaHistoryItem
     */
    id?: string;
    /**
     * User ID
     * @type {string}
     * @memberof IdeaHistoryItem
     */
    userId?: string;
    /**
     * ID of the search associated
     * @type {string}
     * @memberof IdeaHistoryItem
     */
    searchId?: string;
    /**
     * Timestamp of the search
     * @type {string}
     * @memberof IdeaHistoryItem
     */
    timestamp?: string;
    /**
     * Original user's query
     * @type {string}
     * @memberof IdeaHistoryItem
     */
    originalQuery?: string;
}

/**
 * Check if a given object implements the IdeaHistoryItem interface.
 */
export function instanceOfIdeaHistoryItem(value: object): value is IdeaHistoryItem {
    return true;
}

export function IdeaHistoryItemFromJSON(json: any): IdeaHistoryItem {
    return IdeaHistoryItemFromJSONTyped(json, false);
}

export function IdeaHistoryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdeaHistoryItem {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'userId': json['user_id'] == null ? undefined : json['user_id'],
        'searchId': json['search_id'] == null ? undefined : json['search_id'],
        'timestamp': json['timestamp'] == null ? undefined : json['timestamp'],
        'originalQuery': json['original_query'] == null ? undefined : json['original_query'],
    };
}

export function IdeaHistoryItemToJSON(value?: IdeaHistoryItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'user_id': value['userId'],
        'search_id': value['searchId'],
        'timestamp': value['timestamp'],
        'original_query': value['originalQuery'],
    };
}

