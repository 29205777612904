import { ProjectOutput } from '../../api/openapi'
import { IconButton } from '../buttons/IconButton'
import { IoMdSettings } from 'react-icons/io'
import LocalStorageUtil from '../../utils/LocalStorageUtil'
import { IoKeySharp } from 'react-icons/io5'
import { MdOutlineHandshake } from 'react-icons/md'

export const ProjectCard = ({
    project,
    onSettingsClick,
    onProjectClick,
}: {
    project: ProjectOutput
    onSettingsClick: () => void
    onProjectClick: () => void
}) => {
    return (
        <div className={`flex flex-col items-center gap-2`}>
            {project.owner.toLowerCase() ===
                LocalStorageUtil.getEmail()!.toLowerCase() && (
                <div className={`flex flex-row items-center gap-1 text-yellow`}>
                    <div className={`font-bold`}>Owner</div>
                    <IoKeySharp />
                </div>
            )}
            {project.owner.toLowerCase() !==
                LocalStorageUtil.getEmail()!.toLowerCase() && (
                <div
                    className={`flex flex-row items-center gap-1 text-darkGray dark:text-veryLightGray`}
                >
                    <div className={`font-bold`}>Collaborator</div>
                    <MdOutlineHandshake />
                </div>
            )}

            <div
                onClick={onProjectClick}
                className={`flex flex-col justify-between gap-2 bg-lightGray dark:bg-darkBlue project-card-width px-5 pt-4 pb-3 rounded-md
                 shadow-blueCustomShadow cursor-pointer border border-blue hover:shadow-yellowCustomShadow hover:border-yellow`}
            >
                <div className={`flex flex-col gap-2 px-5`}>
                    <div className={`text-blue  text-lg font-bold text-center`}>
                        {project.title}
                    </div>

                    {/*horizontal line*/}
                    <div className={`border-b border-yellow`} />

                    <div
                        className={`flex flex-row gap-x-6 text-sm text-yellow dark:text-yellow`}
                    >
                        {project.tags.map((tag) => (
                            <div key={tag}>{tag}</div>
                        ))}
                    </div>

                    <div
                        className={`text-md text-veryDarkGray dark:text-veryLightGray`}
                    >
                        {project.description}
                    </div>
                </div>
                <div className={`flex flex-row justify-between items-center`}>
                    <div
                        className={`flex flex-col item text-sm text-veryDarkGray dark:text-veryLightGray`}
                    >
                        <div>
                            <span className={`font-bold`}>Owner: </span>{' '}
                            <span>{project.owner}</span>
                        </div>
                        <div>
                            <span className={`font-bold`}>Collaborators: </span>{' '}
                            {project.collaborators.map((collaborator) => (
                                <span key={collaborator}>{collaborator}</span>
                            ))}
                        </div>
                    </div>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation()
                            onSettingsClick()
                        }}
                        icon={<IoMdSettings className={`w-6 h-6`} />}
                    />
                </div>
            </div>
        </div>
    )
}
